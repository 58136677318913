$citizen-summary-color-grey: $color-key-grey;
$citizen-summary-link-color: $color-orange;
$citizen-summary-detail-border: 1px solid rgba(0, 0, 0, .2);
$citizen-summary-padding: $padding;
$citizen-summary-font-color: $color-black;

$citizen-summary-sync-error-color: $color-error;

.citizen-summary {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: space-between;
    padding: $citizen-summary-padding;

    >* {
        width: 100%;
    }

    &__hidden-scroll {
        flex: 1;
        overflow: hidden;
    }

    &__header-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: $citizen-summary-detail-border;
        margin-bottom: $citizen-summary-padding*0.5;

        &_main {
            align-items: flex-start;
        }

        &_noborder {
            border-bottom: 0;
        }
    }

    &__header {

        &_has-short-cut {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 90%;
                left: 0;
                width: 15px;
                height: 0;
                border: 1px solid $citizen-summary-font-color;
            }
        }

        .sync-status {

            &-error {

                svg path {
                    fill: $citizen-summary-sync-error-color;
                }

            }


        }

    }

    &__subheader {
        @include body-copy;
        color: $citizen-summary-color-grey;
    }

    &__call-btn {
        appearance: none;
        color: inherit;
        display: inline;
        padding: 0;
        margin: 0;
    }

    &__special-information {
        display: block;
        margin-bottom: $citizen-summary-padding;
    }

    &__customer-card {
        margin-bottom: $citizen-summary-padding;
    }

    &__link {
        @include body-copy;
        position: relative;
        color: $citizen-summary-link-color;
        cursor: pointer;

        &__has-short-cut {
            &:before {
                content: "";
                position: absolute;
                top: 95%;
                left: 0;
                width: 9px;
                height: 0;
                border: 1px solid $citizen-summary-link-color;
            }
        }
    }

    &__history {
        margin-bottom: $citizen-summary-padding;
        &__header-section {
            margin-bottom: 0;
        }
    }

   

    &__action-buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 6px;

        @media (min-width: 1025px) and (max-width: 1280px) {
            grid-template-columns: repeat(1, 1fr);
        }    
        @media (min-width: 1281px) and (max-width: 1580px) {
            svg {
                display: none !important;
            }
        }    
    }

    &__action-button {
        min-width: 48%;
    }  
}
