$commerce-summary-highlight: $color-orange;
$commerce-summary-spacing: $padding-small;
$commerce-summary-grey: $color-key-grey;

.commerce-summary {
    @include row;

    &__key {
        @include col(8, 8, 2);
        font-weight: 500;
        color: $commerce-summary-grey;
    }

    &__value {
        @include col(8, 8, 2);
        text-align: right;

        &_highlight {
            color: $commerce-summary-highlight;
        }
    }

    &__amount {
        width: 100%;
        font-size: 20px;
        text-align: right;
        font-weight: bold;
        margin-top: $commerce-summary-spacing;
    }
}
