.mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0 !important;

    &:last-of-type {
        border-bottom: 1px solid $color-divider;
    }

    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
        padding: 0 !important;
    }

    .mat-expansion-panel-header {
        position: relative;
        border-top: 1px solid $color-divider;
        padding: $padding-small 0 $padding-small 40px !important;
        height: 48px;
        &::before {
            content: '+';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            font-size: 20px;
            text-align: center;
        }

        &.mat-expanded::before {
            content: '-';
        }

        &-title,
        &-description {
            user-select: none;
            align-items: flex-start;
        }
    }

    .mat-expansion-panel-content {
        border-top: 1px solid $color-divider;
    }

    .mat-content {
        flex-direction: column !important;
    }

    &.mat-expansion-panel-spacing {
        margin: 0 0 16px !important;
    }
}
