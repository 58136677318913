$reservation-space: 10px;
$reservation-icon-size: 30px;
$reservation-border-size: 6px;
$reservation-color-opacity: .3;
$reservation-arrow-size: 10px;

iv-commerce-basket-item-reservation {
    position: relative;
    flex: 0 1 100%;
    width: 100%;
    margin-bottom: 0; // this will override the 5px of padding and will make it together with bottom line

    &.commerce-basket-item-reservation--is-mini-basket {
        margin-top: 15px;
        margin-bottom: #{$reservation-space*0.5};
    }

    &.commerce-basket-item-reservation--has-warning,
    &.commerce-basket-item-reservation--not-available {
        position: relative;

        &::before {
            bottom: calc(100% -  #{$reservation-arrow-size});
            left: 50%;
            border: $reservation-arrow-size solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: rgba($color-alert-info, $reservation-color-opacity);
            margin-left: -$reservation-arrow-size;
        }
    }

    &.commerce-basket-item-reservation--not-available {
        &::before {
            border-bottom-color: rgba($color-alert-danger, $reservation-color-opacity);
        }
    }
}

.commerce-basket-item-reservation {

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: $reservation-space;
        margin-top: $reservation-space;
        border-radius: 6px;

        @include respond-to(tablet, desktop) {
            flex-wrap: nowrap;
        }

        &--has-warning {
            background-color: rgba($color-alert-info, $reservation-color-opacity);
        }

        &--not-available {
            background-color: rgba($color-alert-danger, $reservation-color-opacity);
        }

        &--is-mini-basket {
            flex-wrap: nowrap;
            align-items: flex-start;
        }
    }


    &__content {
        display: flex;
        align-items: center;
        flex: 0 1 auto;
        margin-right: 20px;

        &--is-mini-basket {
            align-items: flex-start;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        width: $reservation-icon-size;
        height: $reservation-icon-size;
        margin-right: $reservation-space;

        .svg-icon {
            path {
                fill: $color-black;
            }

        }
    }

    &__alternative-btn {
        flex: 1 0 auto;
        max-width: 25%;

        // for some reason the icon is not centered in the middle of the fab-button
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-wrapper {
            padding: $reservation-space 0 !important;
        }

        .commerce-basket-item-reservation--is-mini-basket & {
            margin-left: $reservation-space;
        }
    }
}
