$table-row-color: $color-light-grey;
$table-cell-spacing: $padding-small;

$table-first-cell-width: 140px;

.dynamic-table {

    .cdk-header-cell,
    .cdk-cell {
        text-align: center;
        padding: 0 8px;
        flex: 1 0;
        justify-content: center;

        &:first-child {
            text-align: left;
            justify-content: flex-start;
            min-width: $table-first-cell-width;
        }

        &:last-child {
            text-align: right;
            justify-content: flex-end;
        }
    }

    .cdk-header-cell {
        text-align: center;
        justify-content: flex-start;
    }

    // we need to use the .mat-mdc-table .mat-mdc-row to override the default behaviour
    .mat-mdc-table .mat-mdc-row {
        overflow: hidden;
        cursor: pointer;

        &:nth-child(even) {
            background-color: $table-row-color;
        }

        &:hover {
            background-color: darken($table-row-color, 5%);
        }
    }

    div[class*=detailExpand] {
        background: none;
        padding: 0;
    }


    .dynamic-table {
        width: 100%;

        .cdk-header-row {
            display: none;
        }

        .cdk-cell {
            &:first-child {
                padding-left: $table-cell-spacing;
                padding-right: $table-cell-spacing;
            }
        }

        // we need to use the .mat-mdc-table .mat-mdc-row to override the default behaviour
        .mat-mdc-table .mat-mdc-row {
            cursor: default;
            pointer-events: none;

            &:nth-child(even),
            &:nth-child(odd){
                background-color: inherit;
            }
        }

        .cdk-row:last-child {
            border-bottom: none;
        }
    }

}
