$commerce-product-list-border-color: $color-divider;
$commerce-product-list-head-text: $color-key-grey;
$commerce-product-list-head-padding: 10px;

.commerce-product-list {
    &__head {
        border-top: 1px solid $commerce-product-list-border-color;
        border-bottom: 1px solid $commerce-product-list-border-color;
        color: $commerce-product-list-head-text;
        padding: $commerce-product-list-head-padding 0;
        font-size: 12px;
        line-height: 12px;
    }

    &__image {
        text-align: center;
    }

    &__prices {
        text-align: right;
    }

    &__addtobasket {
        text-align: center;
    }
}
