.citizen-inactivate {
    & .mat-step-icon {
        fill: currentColor;

        .svg-icon {
            width: 12px;
            height: 12px;
        }
    }

    & .mat-stepper-vertical {
        overflow: hidden;
    }
}
