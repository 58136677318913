.citizen-creditnotes {
    &__subtitle {
        flex-direction: column;
        align-items: flex-start;
    }
}

.create-credit-note {
    &__content {
        &.mat-mdc-dialog-content {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: hidden;

            @include respond-to(mobile) {
                flex-wrap: wrap;
                width: auto;
            }
        }
    }

    &__hidden-scroll {
        flex: 1;

        @include respond-to(mobile) {
            .util-hidden-scroll {
                height: calc(100vh - 48px) !important;
            }
        }
    }

    &__btn {
        display: inline-block;
    }

    &__input {
        .mat-mdc-form-field {
            width: 250px !important;
        }
    }

    &__action-btn {
        margin-right: 1rem;
    }

    &__spinner {
        display: inline-block;
    }
}
