.administration-create-user {

    &__spinner {
        display: flex;
        min-height: 50vh;
        align-items: center;
        justify-content: center;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    &__spacer {
        flex: 1;
    }

    &__btn {

        &--update,
        &--create {
            align-self: flex-end;
        }
    }
}
