$category-menu-level-device-highlight: $color-orange;

.category-menu-level-device {
    &__show-all {
        @include when-inside('.layout-navigation-device__item_active') {
            display: none;
        }
    }

    &__highlight {
        color: $category-menu-level-device-highlight;
    }
}
