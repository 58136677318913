$list-item-layout-image-size-desktop: $product-list-image-size-desktop;
$list-item-layout-image-size-tablet: $product-list-image-size-tablet;
$list-item-layout-image-size-mobile: $product-list-image-size-mobile;

.list-item-layout {
    @include row;
    align-items: center;
    overflow: hidden;

    &__image {
        margin-right: 10px;
        flex-basis: $list-item-layout-image-size-desktop;
        max-width: $list-item-layout-image-size-desktop;

        @include respond-to(tablet) {
            flex-basis: $list-item-layout-image-size-tablet;
            max-width: $list-item-layout-image-size-tablet;
        }

        @include respond-to(mobile) {
            margin-right: 5px;
            flex-basis: $list-item-layout-image-size-mobile;
            max-width: $list-item-layout-image-size-mobile;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__row {
        @include row;
    }

    &__info {
        @include col;

        @include respond-to(tablet, desktop) {
            margin-right: 5px;
        }
    }

    &__id {
        @include col(2, 2);
        text-align: center;
        margin-right: 5px;

        @include respond-to(mobile, tablet) {
            display: none;
        }
    }

    &__favorite {
        @include respond-to(mobile, tablet) {
            display: none;
        }
    }

    &__prices {
        @include col(3, 4);

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__addtobasket {
        @include respond-to(tablet, desktop) {
            margin-left: 20px;
            flex-basis: 140px;
            min-width: 140px;
        }

        @include respond-to(mobile) {
            margin-left: 10px;
            min-width: 80px;
            flex-basis: 80px;
        }
    }

    &.commerce-product-list__head {
        .list-item-layout__row {
            justify-content: flex-end;
        }

        .list-item-layout__prices {
            margin-left: 20px;

            @include respond-to(tablet) {
                @include offset($t-cols: 6);
            }
        }

        .list-item-layout__addtobasket {
            @include respond-to(mobile) {
                @include offset($m-cols: 3);
            }
        }
    }
}
