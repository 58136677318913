$notesSize: 12px;

.municipality-pda-statistics {

    // we need to extend the button to make it look like the other buttons
    &__export-button {
        width: 25%;

        .mdc-button__label {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: $padding-small;
        }
    }


    &__loading {
        display: flex;

        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 100%;
    }

    .mat-expansion-panel-header-description {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
    }

    &__expansion {
        display: block;
        margin-top: $padding;
    }

    &__total,
    &__expansion-pda,
    &__expansion-fax {
        font-size: $notesSize;
    }

    .mat-mdc-cell:not(:first-child),
    .mat-mdc-header-cell:not(:first-child) {
        text-align: center;
    }
}
