$commerce-basket-item-border: $color-divider;
$commerce-basket-item-grey-text: $color-grey-link;
$commerce-basket-item-discount-color: $color-orange;
$commerce-basket-item-padding: 5px;

$commerce-basket-item-size-desktop: $basket-item-image-size-desktop;
$commerce-basket-item-size-device: $basket-item-image-size-device;

.commerce-basket-item {
    $root: &;

    position: relative;
    border-bottom: 1px solid $commerce-basket-item-border;
    padding: 5px 0;

    // Instead of adding a not-available class to each html element
    // we can match them throught this
    &--not-available {
        #{$root}__image,
        #{$root}__info,
        #{$root}__id,
        #{$root}__favorite,
        #{$root}__prices,
        #{$root}__total-row-price {
            opacity: 0.5
        }
    }

    &--has-warning {

    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__content {
        padding: 20px 0;

        @include respond-to(desktop) {
            min-height: $commerce-basket-item-size-desktop;
        }

        @include respond-to(mobile, tablet) {
            min-height: $commerce-basket-item-size-device;
        }

        @include respond-to(mobile) {
            padding: 5px 0;
        }
    }

    &__id {
        color: $commerce-basket-item-grey-text;
        font-size: 16px;
    }

    &__favorite {}

    &__prices {
        text-align: right;

        &_mobile {
            @include respond-to(tablet, desktop) {
                display: none;
            }

            @include respond-to(mobile) {
                display: flex;
                flex-wrap: wrap;
                font-weight: 500;
                margin: 5px 0;
            }
        }
    }

    &__info {
        @include respond-to(tablet, desktop) {
            @include truncate-text;
        }
    }

    &__name {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;

        @include respond-to(tablet, desktop) {
            @include truncate-text;
        }
    }

    &__description {
        color: $commerce-basket-item-grey-text;

        @include respond-to(tablet, desktop) {
            @include truncate-text;
        }
    }

    &__discount-price {
        color: $commerce-basket-item-discount-color;
        font-size: 16px;
        line-height: 20px;

        @include respond-to(mobile) {
            margin-right: 5px;
        }
    }

    &__price {
        font-size: 16px;
        line-height: 20px;

        &_line-through {
            text-decoration: line-through;
            color: $commerce-basket-item-grey-text;
            line-height: 18px;
            font-size: inherit;

            @include respond-to(mobile) {
                line-height: 20px;
            }
        }
    }

    &__unit-price {
        color: $commerce-basket-item-grey-text;
        line-height: 18px;

        &_mobile {
            display: none;

            @include respond-to(mobile) {
                display: inline;
            }
        }
    }

    &__total-row-price {
        font-size: 16px;
        line-height: 20px;
        text-align: right;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
    }

    &__labels {
        .commerce-labels {
            margin-top: 5px;
        }

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__chips {
        margin-top: 5px;
    }

    &__chip {
        margin: 0 10px 5px 0 !important;

        &:last-child {
            margin: 0 0 5px 0 !important;
        }
    }
}
