$search-field-background: $color-search-grey;
$search-field-margin: $padding-small;
$search-field-margin-mobile: 5px;
$search-field-size: 30px;
$search-field-size-mobile: 20px;

$search-field-suggestions-background: $color-white;
$search-field-suggestions-background-active: $color-search-grey;
$search-field-suggestions-border-color: $color-border-grey;
$search-field-suggestions-category-color: $color-orange;

.search-field {
    position: relative;
    margin: $search-field-margin 0;

    @include respond-to(mobile, tablet) {
        margin: $search-field-margin-mobile 0;
    }

    &__input {
        @include truncate-text;
        width: 100%;
        border-radius: 50px;
        background-color: $search-field-background;
        line-height: $search-field-size;
        padding: 10px 50px 10px $search-field-size;

        @include respond-to(mobile, tablet) {
            line-height: $search-field-size-mobile;
            padding: 10px 40px 10px $search-field-size-mobile;
        }
    }

    &__icon,
    &__spinner.mat-mdc-progress-spinner {
        display: flex;
        align-items: center;
        position: absolute;
        right: $search-field-size;
        height: $search-field-size;
        top: 50%;
        transform: translateY(-50%);

        @include respond-to(mobile, tablet) {
            right: $search-field-size-mobile;
            height: $search-field-size-mobile;
        }

        .svg-icon {
            height: 20px;
            width: 20px;
        }
    }

    &__spinner.mat-mdc-progress-spinner {
        &.mat-accent {
            circle {
                stroke: rgb(149, 161, 180);
            }
        }
    }

    &__suggestions {
        position: absolute;
        left: 0;
        top: 100%;
        width: calc(100% - 50px);
        margin: 0 25px;

        background: $search-field-suggestions-background;
        border: 1px solid $search-field-suggestions-border-color;
        border-top: 0;
        box-shadow: rgba(0,0,0,0.3) 0 5px 5px;

        &-container {
            @include ul-reset;
        }

        &-item {
            @include truncate-text;
            display: block;
            padding: .5rem 2rem;
            transition: background-color .25s ease;

            &:hover,
            &.active {
                cursor: pointer;
                background-color: $search-field-suggestions-background-active;
            }

            & > em {
                @include body-copy;
            }

            &.search {
                @include body-copy-bold;

                & + .category {
                    border-top: 1px solid $search-field-suggestions-border-color;
                }
            }

            &.category {
                & > em {
                    color: $search-field-suggestions-category-color;
                }
            }
        }
    }
}
