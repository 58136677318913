$layout-footer-border-color: $color-divider;
$layout-footer-spacing: $padding;
$layout-footer-key: $color-key-grey;

iv-layout-footer {
    display: block;
}

.layout-footer {
    @include row;
    border-top: 1px solid $layout-footer-border-color;
    margin-top: $layout-footer-spacing;
    padding: $layout-footer-spacing 0;

    &__col {
        @include col($t-cols: 8, $m-cols: 4);

        @include respond-to(mobile) {
            margin-bottom: $layout-footer-spacing;
        }
    }

    &__row {
        @include row;
        margin-bottom: .5rem;
    }

    &__key {
        @include col;
        color: $layout-footer-key;
    }

    &__value {
        flex: 2;
    }

    &__control-report {
        @include respond-to(tablet) {
            @include col($t-cols: 16);
            display: flex;
            justify-content: center;
            margin-top: $layout-footer-spacing;
        }

        &-link {
            display: flex;
            align-items: center;
            flex-direction: column;

            @include respond-to(desktop) {
                float: right;
            }
        }

        &-icon {
            background-color: $color-black;
            color: $color-white;
            width: 48px;
            height: 48px;
            border-radius: 100%;

            .svg-icon {
                margin-top: -1px;
                margin-left: -1px;
                width: 50px;
                height: 50px;
            }
        }
    }
}
