$success-feedback-padding: $padding-small;

.form {
    @extend .clearfix;
    width: 80%;
    margin-bottom: px-to-rem(20);
    display: flex;
    flex-direction: column;

    &--no-margin-bottom {
        margin-bottom: 0;
    }

    &__element {
        width: 100% !important;
        margin-bottom: px-to-rem(10);
    }

    &__radio-button.mat-mdc-radio-button {
        display: block;
        cursor: pointer;
        margin-bottom: px-to-rem(5);
    }

    &__radio-button--as-sub-option {
        margin-left: px-to-rem(24);
    }

    @include respond-to(mobile) {
        width: 100%;
    }
}

input, button, textarea {
    @include no-outline;
}

.success-feedback {

    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    margin-top: 40px;

    &__text {
        margin-bottom: $success-feedback-padding;
    }

}
