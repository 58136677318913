// -----------------------------------------------------------------------------
// This file contains styling for the table item component
// -----------------------------------------------------------------------------


/// ========================================================================
/// TABLE COMPONENT
/// ========================================================================

.table {
	border-spacing: 0;
	margin-bottom: 10px;
	padding-bottom: 10px;
	width: 100%;
}

.table__head{
    @include body-copy-bold;
	text-align: left;
	padding-top: 10px;
}

.table__col {
    @include body-copy;
	padding: 5px 0;
	padding-left: 5px;

	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		text-align: right;
	}
}

.table__col_one-line {
	white-space: nowrap;
	padding-right: 20px;
}

.table__col_grow {
	width: 100%;
}
