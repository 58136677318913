.administration-settings {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    &__option-group {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
    }

    &__message {
        margin-left: 44px;
        margin-top: 10px;
        color: $color-dark-grey;
    }
}
