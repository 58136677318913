$layout-cookie-warning-padding: 5px;
$layout-cookie-warning-background: rgba($color-green, 0.9);
$layout-cookie-warning-text: $text-contrast;

.layout-cookie-warning{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: z('over-middle');
    color: $layout-cookie-warning-text;
    background-color: $layout-cookie-warning-background;

    &__container{
        @include container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: $layout-cookie-warning-padding;
        padding-bottom: $layout-cookie-warning-padding;
    }

    &__text{
        margin-right: 10px;
    }
}
