$calendar-margin: 3px;
$calendar-event-border-radius: 3px;
$calendar-slot-height: 17px;
$calendar-event-color-green: #7cb77e;
$calendar-event-color-white: #fff;
$calendar-event-color-red: #e47776;

.cal-1 {
    display: block;
    width: 480px;
    margin-right: 30px;
    margin-bottom: 30px;
    float: left;
}

.call-list-admin {
    &__calendar {
        display: flex;
    }

    &__refresh {
        .icon {
            position: relative;
            top: 5px;
        }
    }
}

iv-drag-slots-calendar,
iv-drag-events-calendar {
    .times {
        margin-top: 18px;
    }

    .time {
        width: 30px;
        text-align: center;
        height: $calendar-slot-height;
    }

    .columns {
        flex: 1;
        display: flex;
        overflow: hidden;
        overflow-x: auto;
    }

    .column {
        flex: 1 0 20%;
        max-width: 20%;
        margin-right: 2rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .title {
        @include truncate-text;
        background: #eaeaea;
        text-align: center;
        padding: 5px 0;
        display: block;
        width: 100%;

        &:hover {
            text-decoration: underline;
        }

        &.selected {
            background: darken(#eaeaea, 5%);
            font-weight: bold;
        }
    }

    .slot {
        position: relative;
        height: $calendar-slot-height;
        box-sizing: border-box;
        display: flex;
    }

    .slot:nth-child(4n+1) {
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            display: block;
            width: calc(100% + 2rem);
            border-bottom: 1px solid #d3d3d3;
        }
    }

    .column:last-child .slot:nth-child(4n+1)::after {
        width: 100%;
    }
}

iv-drag-slots-calendar .slot.draghover:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: $calendar-margin;
    left: 0;
    right: 0;
    display: block;
    background: #ddd;
    pointer-events: none;
    border-radius: $calendar-event-border-radius;

}

iv-drag-slots-calendar .slot.draghover[data-length="30"]:before {
    bottom: -17px;
}

iv-drag-slots-calendar .slot.draghover[data-length="45"]:before {
    bottom: -37px;
}

iv-drag-slots-calendar .slot.draghover[data-length="60"]:before {
    bottom: -57px;
}

.mat-mdc-selection-list {
    overflow: hidden;
    contain: content;
    width: 150px;
}

.dragdirective {
    cursor: all-scroll;
}

.draggers .dragdirective {
    background: $calendar-event-color-green;
    border-radius: $calendar-event-border-radius;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    width: 157px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    height: ($calendar-slot-height*1)-$calendar-margin;
}

.dragdirective.min-30 {
    height: ($calendar-slot-height*2)-$calendar-margin;
}

.dragdirective.min-45 {
    height: ($calendar-slot-height*3)-$calendar-margin;
}

.dragdirective.min-60 {
    height: ($calendar-slot-height*4)-$calendar-margin;
}

.dragdirective.type-P {
    background-color: $calendar-event-color-white;
    color: #000;
}

.dragdirective.drag-disallowed {
    cursor: no-drop;
}

iv-drag-slots-calendar .booking,
iv-drag-events-calendar .booking {
    @include truncate-text;
    display: block;
    background: $calendar-event-color-green;
    border-radius: $calendar-event-border-radius;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    transition: background-color 0.2s;
    width: calc(100% - #{$calendar-margin});
    margin-right: $calendar-margin;

    &:last-child {
        margin-right: 0;
    }

    /* funny syntax for variables inside calc-exp */
    &:hover {
        text-decoration: underline;
        background: darken($calendar-event-color-green, 3%);
    }

    &.selected {
        background: darken($calendar-event-color-green, 10%);
    }

    &.booking-red {
        background: $calendar-event-color-red;
    }

    &.booking-white {
        background: $calendar-event-color-white;
        color: #333;
        border: 1px solid #999;
    }
}

iv-drag-slots-calendar .booking-30,
iv-drag-events-calendar .booking-30 {
    height: $calendar-slot-height*2 - $calendar-margin;
}

iv-drag-slots-calendar .booking-45,
iv-drag-events-calendar .booking-45 {
    height: $calendar-slot-height*3 - $calendar-margin;
}

iv-drag-slots-calendar .booking-60,
iv-drag-events-calendar .booking-60 {
    height: $calendar-slot-height*4 - $calendar-margin;
}

.booking.draghover {
    background: #95e0d7
}

.edit-icon svg {
    width: 12px;
    margin-left: 5px;
}

iv-assign-citizens,
iv-release-fourth-try,
iv-reopen-end,
iv-end-day,
iv-end-fax-day,
iv-edit-caller,
iv-edit-call-list,
iv-generate-list,
iv-display-callcount,
iv-edit-call-day {
    display: block;
    min-width: 320px;
}

iv-administration-calllistadmin-planning,
iv-administration-calllistadmin-calllist-list,
iv-administration-calllistadmin-userlist {
    mat-progress-spinner {
        margin: 15px auto;
    }
}


.app__content iv-call-list-page,
.app__content iv-planning-page {
    display: flex;

    iv-drag-events-calendar,
    iv-drag-slots-calendar {
        width: 72%;
    }

    .mat-mdc-tab-group {
        width: 28%;
        min-height: 200px;
    }

    .mat-mdc-selection-list,
    .mat-mdc-list {
        min-height: 200px;
        padding-top: 0;

        .mat-mdc-list-option,
        .mat-mdc-list-item {
            height: 30px;

            .mdc-list-item__start {
                margin-right: 0;
                margin-left: 0;
                padding: 0 5px;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            .mat-mdc-list-option .mat-list-text {
                padding: 0 10px;
            }
        }
    }


    .mat-mdc-list-option,
    .mat-mdc-list-item {
        @include truncate-text();
    }

    .fetching {

        .mat-mdc-list-option {
            opacity: 0.2;
        }

        .spinner {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 50%;
            top: 100px;
            transform: translate(-50%, -50%);
        }
    }
}

iv-call-list-page,
iv-planning-page {

    .mat-mdc-tab-label {
        min-width: 100px;
        padding: 0 10px;
    }

    .mat-mdc-tab-body {
        padding: 10px 15px;
        margin-right: -60px;
    }

    .mat-mdc-selection-list {
        width: 100%;
    }
}

iv-edit-caller,
iv-edit-call-list {

    .mat-mdc-form-field {
        width: 100%;
    }

}

iv-generate-list {
    .mat-form-field-appearance-legacy {
        .mat-mdc-form-field-hint {
            &.hint-warning {
                color: $color-error;
            }
        }
    }
}
