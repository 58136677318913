%svg-icon {
    display: inline-flex;
    background-repeat: no-repeat;
    fill: currentColor;

    svg {
        width: 100%;
        height: 100%;
    }
}

.icon {
    @extend %svg-icon;

    >.svg-icon {
        width: 20px;
        height: 20px;
    }
}


.mat-mdc-button-base.btn-with-icon {
    @extend %svg-icon;

    .mdc-button__label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .progress-button__text svg {
        width: 20px;
        height: 20px;
    }

    .svg-icon {
        max-height: 15px;
    }

    &_last {
        .svg-icon {
            margin-right: 0;
            margin-left: 5px;
        }
    }
}
