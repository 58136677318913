.commerce-product-filter-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__filter {
        @include row;
        justify-content: space-between;
        align-items: center;
        height: 70px;
    }

    &__quick-link {
        display: none;
        padding: 5px 10px;
        border: 1px solid $color-divider;
        border-radius: 4px;
        margin-right: 5px;

        &--active {
            background: $color-grey-input;
            color: $color-grey-link;
            border: 1px solid $color-grey-input;
        }

        @include respond-to(mobile, tablet) {
            display: inline-block;
        }
    }
}
