.mat-mdc-select {
    --mat-select-trigger-text-size: map-get($font-sizes, small);
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(0px) !important;
}

 .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    line-height: 20px !important;
} 

.mat-stepper-vertical{
    .mat-vertical-content {
        padding-bottom: 0 !important;
    }
}

.mat-mdc-tab {
    --mat-tab-header-inactive-label-text-color: $color-black;
    --mat-tab-header-inactive-focus-label-text-color: $color-black;
    --mat-tab-header-inactive-hover-label-text-color: $color-black;
}