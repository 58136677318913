.mat-mdc-button-base {
    .mdc-button__label {
        display: inline-flex;
        line-height: 16px;
    }
}

// we need to extend the button to make it look like the other buttons
.button__export-button {
    width: 25%;

    .mdc-button__label {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: $padding-small;
    }
}
