$municipality-padding: $padding-small;

.municipality-view-citizens {

    // we need to extend the button to make it look like the other buttons
    &__export-button {
        width: 25%;

        .mdc-button__label {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: $padding-small;
        }
    }

    &__fetching {
        display: flex;

        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 100%;
    }

    &__loading,
    &__results-empty {
        padding: $municipality-padding 0;
    }

    &__loading {
        display: flex;
        justify-content: center;
    }

    /* Material Table */
    &__table {
        margin-top: $municipality-padding;

        &-head,
        &-body {

            &.mat-mdc-cell,
            &.mat-mdc-header-cell {
                padding: 0 24px;
            }

            // Table head Address
            &:nth-child(2) {
                flex: 2;
            }

            &:nth-child(3) {
                flex: 1.5;
            }

            // Actions column
            &:nth-child(4) {
                justify-content: center;
                text-align: center;
            }

            // Status (active or inactive) column
            &:nth-child(5) {
                text-align: center;
                justify-content: center;
            }
        }
    }
}
