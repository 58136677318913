.mat-mdc-form-field-type-mat-input,
.mat-mdc-form-field-type-mat-select,
.mat-mdc-form-field-type-mat-chip-grid {
    .mdc-text-field {
        // padding-left: 0;
        background: transparent;
    }

    .mat-mdc-form-field:hover,
    .mat-mdc-form-field-focus-overlay {
        background: transparent;
    }
}

// Global form fields styles and overrides

.mat-mdc-form-field {
    padding-left: 0;

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding-left: 0;
    }
}

iv-notes-admin-create-cause,
iv-notes-admin-create-subcause,
iv-notes-admin-create-subsubcause {
    .mdc-dialog__content {
        margin: 20px 24px 20px 24px !important;
        padding: 0 !important;
    }

    .util-hidden-scroll_active {
        max-height: 65vh;
    }

    .alt-theme .mat-mdc-form-field {
        &-subscript-wrapper {
            position: relative;
        }
    }
}

.mat-mdc-paginator-page-size {
    .mat-mdc-paginator-page-size-label {
        color: $color-grey;
    }

    .mat-mdc-paginator-page-size-select {
        width: 56px;

        .mat-mdc-form-field-infix {
            min-height: auto;
            padding: 0 !important;
        }

        .mdc-text-field {
            padding: 0 !important;
        }
    }
}

// Radio Group elements
.form__radio {
    &--vertical {
        .mat-mdc-radio-group {
            display: flex;
            flex-direction: column;
            // margin: 0 0 15px -10px; // we need to adjust the position of the radio buttons group in order to be aligned
            align-items: flex-start;
        }
    }

    &--vertical-grid {
        .mat-mdc-radio-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 0;
        }
    }
}

// To remove whitesmoke background color from form fields
.mat-mdc-form-field {
    --mdc-filled-text-field-container-color: transparent;
    --mdc-filled-text-field-disabled-container-color: transparent;
    --mdc-filled-text-field-input-text-color: $color-black;
    --mdc-filled-text-field-label-text-color: $color-black;

    .mat-mdc-input-element {
        --mdc-filled-text-field-input-text-color: #000000;
    }
}

.alt-theme {
    .mat-mdc-form-field {
        padding-left: initial;

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
            padding-left: 16px;
        }
    }

    .mdc-text-field {
        padding-left: 16px;
    }

    /* Form fields */
    .mat-mdc-form-field {
        width: 100%;
        $width-padding: px-to-rem(40);
        position: relative;
        margin-bottom: px-to-rem(10);

        .mat-mdc-text-field-wrapper {
            border-radius: var(--mdc-shape-small, 4px);
            background: $color-grey-input;
        }
        // To avoid the click of the error message
        .mat-mdc-form-field-subscript-wrapper {
            pointer-events: none;
        }

        .mdc-line-ripple {
            display: none;
        }

        &-underline {
            width: calc(100% - #{$width-padding}) !important;
            background-color: inherit;
            bottom: 1em;
            left: 50%;
            transform: translateX(-50%);
        }

        &-subscript-wrapper {
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .mat-mdc-form-field-infix {
            padding-top: 20px;
        }

        .mat-mdc-form-field-error,
        .mat-mdc-form-field-hint {
            font-size: map-get($font-sizes, xsmall);
            font-weight: map-get($font-weights, bold);
        }
    }

    .mat-form-field-disabled {
        .mat-mdc-input-element {
            color: rgba(0, 0, 0, 0.14);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label {
            color: rgba(0, 0, 0, 0.14);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline {
            color: rgba(0, 0, 0, 0.14);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-ripple {
            color: rgba(0, 0, 0, 0.14);
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-required-marker {
            color: rgba(0, 0, 0, 0.14);
        }
    }
}

// styling the forms inside the dialogs
.mdc-dialog {
    .mdc-dialog__content {
       .mat-mdc-form-field {
            width: 100%;
        }
    }
}
