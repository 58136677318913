@use "sass:math";

$administration-menu-background-color: $color-grey;
$administration-menu-detail-border-color: lighten($administration-menu-background-color, 5%);
$administration-menu-item-background-color--hover: $color-dark-grey;
$administration-menu-padding: $padding;
$administration-menu-item-font-color: $text-alt;
$administration-menu-item-font-color--hover: $color-white;
$administration-menu-img-size: 100px;


.administration {
    &-section {
        display: flex;
        height: 100%;
    }

    &-menu {
        display: flex;
        flex-direction: column;
        background-color: $administration-menu-background-color;
        height: 100vh;
        width: 100%;

        &__profile {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            padding: $administration-menu-padding;

            &-img-wrapper {
                position: relative;
                width: $administration-menu-img-size;
                height: $administration-menu-img-size;
                border-radius: 100%;
                margin-bottom: math.div($administration-menu-padding, 2);
                background: white;
                overflow: hidden;

                @include center-content;
            }

            &-img {
                max-width: $administration-menu-img-size;
                max-height: $administration-menu-img-size;
            }

            &-name {
                color: $administration-menu-item-font-color;
                width: 100%;
                text-align: center;
                margin: 0;
            }

            &-status {
                margin-top: math.div($administration-menu-padding, 2);
            }
        }

        &__hidden-scroll {
            flex: 1;
        }

        &__list {
            @include ul-reset;
            width: 100%;
            display: flex;
            flex: 1;
            border-top: 1px solid $administration-menu-detail-border-color;
            overflow: hidden;

            &-item {
                $item: &;
                width: 100%;

                &-link.mat-unthemed {
                    display: block;
                    height: auto;
                    width: 100%;
                    padding: calc($administration-menu-padding - 9px) $administration-menu-padding;
                    border-radius: 0 !important;

                    .mat-ripple {
                        border-radius: 0 !important;
                    }
                }

                &-icon,
                &-text {
                    color: $administration-menu-item-font-color;
                    padding-right: math.div($administration-menu-padding, 2);
                }

                &-icon {
                    display: flex;

                    svg,
                    svg path {
                        width: 24px;
                        height: 24px;
                        fill: $administration-menu-item-font-color;
                    }
                }

                &-text {
                    @include truncate-text;
                    margin: 0;
                }

                &-call-count {
                    letter-spacing: 2px;
                    border-radius: 24px;
                }

                &:hover &-link,
                &-link.active {
                    background-color: $administration-menu-item-background-color--hover;

                    #{$item}-icon {
                        svg path {
                            fill: $administration-menu-item-font-color--hover;
                        }
                    }

                    #{$item}-text {
                        color: $administration-menu-item-font-color--hover;
                    }
                }
            }
        }

        .mdc-button__label {
            display: flex;
            align-items: center;
            max-width: 100%;
        }
    }
}

iv-administration-call-info {
    display: flex;
    width: 100%;
    min-height: 154px;
    text-align: center;
    padding: $administration-menu-padding;
}

.call-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    color: $administration-menu-item-font-color;

    > * {
        width: 100%;
    }

    &-header {
        border-bottom: 1px solid $administration-menu-detail-border-color;
    }

    &-info {
        &-header {
            color: $administration-menu-item-font-color--hover;
        }
    }

    &-button {
        .svg-icon {
            padding-right: math.div($administration-menu-padding, 2);
            $size: 20px;
            height: $size;
            width: $size;
        }

        &-text {
            @include truncate-text;
        }
    }
}
