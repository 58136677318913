.administration-pending-calls {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
