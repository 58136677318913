$app-background: $color-white;
$app-divider: $color-divider;
$app-padding-small: $padding-small;
$app-padding: $padding;
$app-header-height: $header-height;
$app-header-homecarer-height: $header-top-height;
$app-header-impersonated-height: $header-impersonated-height;
$app-header-height-mobile: $header-height-device;
$app-header-homecarer-height-mobile: $header-top-height-device;
$app-header-impersonated-height-mobile: $header-impersonated-height-device;
$app-header-height-admin: $header-bottom-height;
$app-aside-background: $color-light-grey;
$app-aside-width: 250px;
$app-minibasket-width: $minibasket-width;

.app {
    @include respond-to(desktop) {
        @include row;

        &__wrapper {
            /* Fix for IE 11 FlexBox min-height bug */
            display: flex;
            flex-direction: row;
        }
    }

    &_customer {
        @include container;
        margin-top: $app-header-height + $app-padding-small;

        @include respond-to(mobile, tablet) {
            margin-top: $app-header-height-mobile + $app-padding-small;
        }

        &.app_homecarer-mode {
            margin-top: $app-header-homecarer-height + $app-padding-small;

            @include respond-to(mobile, tablet) {
                margin-top: $app-header-homecarer-height-mobile + $app-padding-small;
            }
        }

        &.app_impersonated {
            margin-top: $app-header-impersonated-height + $app-padding-small;

            @include respond-to(mobile, tablet) {
                margin-top: $app-header-impersonated-height-mobile + $app-padding-small;
            }
        }
    }

    &__page {
        width: 100%;

        @include respond-to(desktop) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: calc(100vh - #{$app-header-height + $app-padding-small});
        }

        @include respond-to(mobile, tablet) {
            min-height: calc(100vh - #{$app-header-height-mobile + $app-padding-small});
        }

        @include when-inside(".app_impersonated") {
            @include respond-to(desktop) {
                min-height: calc(100vh - #{$app-header-impersonated-height + $app-padding-small});
            }

            @include respond-to(mobile, tablet) {
                min-height: calc(100vh - #{$app-header-impersonated-height-mobile + $app-padding-small});
            }
        }
    }

    &__version {
        position: fixed;
        bottom: 0;
        right: 0;
        font-size: 10px;
        padding: 2px 2px 0 2px;
        text-align: right;
        z-index: 1000;
    }

    &__article {
        height: 100%;
    }

    &__content router-outlet+* {
        display: block;
        height: calc(100% - 60px);
    }

    @include when-inside(".app_customer") {
        &__aside {
            position: relative;
            flex-basis: $app-aside-width;
            max-width: $app-aside-width;
            margin-right: $app-padding;

            &_hide {
                display: none;
            }

            &-menu {
                position: fixed;
                top: $app-header-height+$app-padding-small;
                width: $app-aside-width;
                overflow-y: auto;

                .util-hidden-scroll {
                    height: calc(100vh - #{$app-header-height + $app-padding-small}) !important;
                }
            }
        }

        &__content {
            @include col;
            position: relative;
            z-index: z('bottom');
            background-color: $app-background;
            max-width: 100%;
        }

        &__commerce-minibasket {
            position: fixed;
            width: $app-minibasket-width;
            height: 100%;
            top: 0;
            right: 0;
            transform: translateX(100%);
            z-index: z('over-bottom');
        }
    }

    &_customer-center {
        overflow: hidden;
    }

    @include when-inside(".app_customer-center") {
        &__administration-menu {
            @include col(2);
            z-index: z('default');
        }

        &__aside {
            @include col(3);
            z-index: z('bottom');
            background-color: $app-aside-background;
            position: relative;
            overflow-x: hidden;

            &-menu {
                position: absolute;
                top: 0;
                width: 100%;
                overflow: hidden;
            }

            &-outlet {
                height: 100%;
                transform: translateZ(0);
            }
        }

        &__content {
            @include col;
            z-index: z('over-bottom');
            background-color: $app-background;
            border-left: 1px solid $app-divider;
            padding: 0 px-to-rem($app-padding);
            overflow: hidden;
            position: relative;

            .util-hidden-scroll {
                margin-top: $app-padding-small;
                height: calc(100vh - #{$app-header-height + $app-padding-small}) !important;
            }
        }

        &__commerce-minibasket {
            z-index: z('middle');
        }
    }

    @include when-inside(".app_customer-center.app_admin-mode") {
        &__content {
            .util-hidden-scroll {
                height: calc(100vh - #{$app-header-height-admin + $app-padding-small}) !important;
            }
        }
    }

    @include when-inside(".app_customer.app_homecarer-mode") {
        &__content {
            .util-hidden-scroll {
                height: calc(100vh - #{$app-header-homecarer-height + $app-padding-small}) !important;
            }
        }
    }

    @include when-inside(".app_customer.app_impersonated") {
        &__aside {
            &-menu {
                top: $app-header-impersonated-height+$app-padding-small;

                .util-hidden-scroll {
                    height: calc(100vh - #{$app-header-impersonated-height + $app-padding-small}) !important;
                }
            }
        }
    }

    @include when-inside(".app_customer-center.app_impersonated") {
        &__content {
            .util-hidden-scroll {
                height: calc(100vh - #{$app-header-impersonated-height + $app-padding-small}) !important;
            }
        }
    }
}
