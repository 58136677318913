.administration-creation-errors {

    .dynamic-table {

        .mat-mdc-cell,
        .mat-mdc-header-cell {

            padding: 0 24px;
        }

        .mat-column {

            &-syncUserId,
            &-syncCustomerId {
                flex: .5;
                min-width: auto;
            }

            &-lastModifiedDate {
                flex: 2;
            }

            &-customerSubType {
                flex: 2;
            }

            &-syncResult {
                flex: 3;
                text-transform: lowercase;
            }

        }

        .mat-mdc-row {

            &__expanded {

                padding: 10px;
                pointer-events: none;

                background: $color-light-grey;

                &-detail {
                    background: $color-black;
                    color: $color-white;
                }

                pre {
                    padding: 10px;
                    margin: 0;

                    white-space: pre-wrap;
                    word-wrap: break-word;
                }

            }
        }

    }

}
