@use "sass:math";

$commerce-minibasket-background: $color-white;
$commerce-minibasket-divider: $color-divider;
$commerce-minibasket-spacing: $padding-small;
$commerce-minibasket-head-height: $header-top-height;
$commerce-minibasket-quantity-text: $color-key-grey;
$commerce-minibasket-width: $minibasket-width;

.commerce-minibasket {
    background-color: $commerce-minibasket-background;
    border-left: 1px solid $commerce-minibasket-divider;
    box-shadow: 5px 0 10px 0 rgba($color-black, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    @include when-inside('.app_customer') {
        width: $commerce-minibasket-width;
    }

    &__top {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 $commerce-minibasket-spacing;
        overflow: hidden;
    }

    &__bottom {
        border-top: 1px solid $commerce-minibasket-divider;
        padding: $commerce-minibasket-spacing;
    }

    &__head {
        margin-top: math.div($commerce-minibasket-head-height, 3);
        border-bottom: 1px solid $commerce-minibasket-divider;
        font-size: 25px;
        line-height: math.div($commerce-minibasket-head-height, 3) * 2 - 1;
    }

    &__items {
        overflow: hidden;
        display: flex;
    }

    &__hidden-scroll {
        flex: 1;

        .util-hidden-scroll {
            margin-right: -150px;
            padding-right: 150px;
        }
    }

    &__status {
        @include center-content;
        flex: 1;
    }

    &__max-buy {
        text-align: center;
        font-size: 12px;
    }

    &__info {
        @include row;
        margin-bottom: 1rem;
    }

    &__quantity {
        @include col;
        color: $commerce-minibasket-quantity-text;
    }

    &__price {
        @include col;
        text-align: right;
        font-weight: 500;
    }

    &__go-to-basket {
        width: 100%;
    }
}
