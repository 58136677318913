$basket-item-layout-image-size-desktop: $basket-item-image-size-desktop;
$basket-item-layout-image-size-device: $basket-item-image-size-device;

.basket-item-layout {
    @include row;
    align-items: center;
    overflow: hidden;

    &__image {
        margin-right: 10px;

        @include respond-to(desktop) {
            flex-basis: $basket-item-layout-image-size-desktop;
            max-width: $basket-item-layout-image-size-desktop;
        }

        @include respond-to(mobile, tablet) {
            flex-basis: $basket-item-layout-image-size-device;
            max-width: $basket-item-layout-image-size-device;
        }

        @include respond-to(mobile) {
            margin-right: 5px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__row {
        @include row;
    }

    &__info {
        @include col;

        @include respond-to(tablet, desktop) {
            margin-right: 5px;
        }
    }

    &__id {
        @include col(2, 2);
        text-align: center;
        margin-right: 5px;

        @include respond-to(mobile, tablet) {
            display: none;
        }
    }

    &__favorite {
        @include respond-to(mobile, tablet) {
            display: none;
        }
    }

    &__prices,
    &__total-row-price {
        @include col(2, 3);

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__addtobasket {
        @include respond-to(tablet, desktop) {
            margin-left: 20px;
            flex-basis: 175px;
            min-width: 175px;
        }

        @include respond-to(mobile) {
            margin-left: 10px;
            min-width: 80px;
            flex-basis: 80px;
        }
    }

    &.commerce-basket__head {
        .basket-item-layout__row {
            justify-content: flex-end;
        }

        .basket-item-layout__prices {
            margin-left: 20px;

            @include respond-to(tablet) {
                @include offset($t-cols: 3);
            }
        }

        .basket-item-layout__addtobasket {
            @include respond-to(mobile) {
                @include offset($m-cols: 3);
            }
        }
    }
}
