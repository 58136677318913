$municipality-edit-password-citizen-padding: $padding-small;

.municipality-edit-password-citizen {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__form {
        @include row(10);
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $municipality-edit-password-citizen-padding;
    }

    iv-form-builder-element,
    &__btn-container,
    &__info {
        @include col(16);
    }

    &__btn {
        float: right;
    }
}
