$commerce-orders-rows-border-color: $color-divider;
$commerce-orders-rows-grey: $color-key-grey;
$commerce-orders-rows-line-through: $color-grey-link;
$commerce-orders-rows-spacing: $padding-small;
$commerce-orders-rows-highlight: $color-orange;

.commerce-orders-rows {
    margin-bottom: $commerce-orders-rows-spacing;

    &__unavailable {
        display: flex;
        justify-content: center;
        padding: 21px 0;
    }

    &__head {
        @include row;
        border-top: 1px solid $commerce-orders-rows-border-color;
        border-bottom: 1px solid $commerce-orders-rows-border-color;
        color: $commerce-orders-rows-grey;
        padding: 10px;
        font-size: 12px;
        line-height: 12px;
    }

    &__row {
        @include row;
        position: relative;
        overflow: hidden;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
        padding: 10px;
        border-bottom: 1px solid $commerce-orders-rows-border-color;

        &.disabled {
            pointer-events: none;
            background-color: rgba($color: $color-key-grey, $alpha: 0.1);
        }

        &.highlighted {
            background-color: rgba($color: $color-alert-info, $alpha: 0.1);
        }
    }

    &__name {
        @include col;
        @include truncate-text;

        cursor: pointer;
    }

    &__id {
        @include col(2, 2);
        text-align: center;
        cursor: pointer;

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__quantity {
        @include respond-to(tablet, desktop) {
            @include col(1, 1);
        }

        @include respond-to(mobile) {
            flex-basis: 35px;
        }

        text-align: center;
    }

    &__info {
        @include respond-to(tablet, desktop) {
            @include col(2, 1);
        }

        @include respond-to(mobile) {
            flex-basis: 35px;
        }

        text-align: left;

        span {
            display: block;
        }
    }

    &__unit-price {
        @include col(2, 4, 1);
        text-align: right;
    }

    &__row-price {
        @include col(3, 3, 1);
        text-align: right;
    }

    &__addtobasket {
        flex-basis: 160px;

        @include respond-to(tablet, mobile) {
            display: none;
        }

        .commerce-addtobasket__quantity-in-basket {
            display: none;
        }
    }

    &__credit {
        flex-basis: 80px;
        text-align: right;

        &-input {
            width: 50px;
            padding: 4px 0;
            text-align: center;
            border: 1px solid $commerce-addtobasket-quantity-border;
            border-radius: 50px;

            &::selection {
                background-color: $commerce-addtobasket-quantity-in-basket;
                color: $color-white;
            }

            &:focus,
            &:active {
                border: 1px solid $commerce-addtobasket-quantity-in-basket;
                color: $commerce-addtobasket-quantity-in-basket;
            }
        }
    }

    &__price-adjustment {
        flex-basis: 100px;
        text-align: right;

        &-input {
            width: 50px;
            padding: 4px 0;
            text-align: center;
            border: 1px solid $commerce-addtobasket-quantity-border;
            border-radius: 50px;
            &::selection {
                background-color: $commerce-addtobasket-quantity-in-basket;
                color: $color-white;
            }

            &:focus,
            &:active {
                border: 1px solid $commerce-addtobasket-quantity-in-basket;
                color: $commerce-addtobasket-quantity-in-basket;
            }
        }
    }

    &__credited {
        text-align: right;
        font-size: 11px;
    }

    &__product-unit-price {
        &_line-through {
            text-decoration: line-through;
            color: $commerce-orders-rows-line-through;
        }
    }

    &__discount-unit-price {
        color: $commerce-orders-rows-highlight;
    }

    &__causes {
        flex-basis: 100%;
        max-width: 100%;
        border-top: 1px solid $commerce-orders-rows-border-color;
        margin-top: 10px;
    }
}
