@use '@angular/material' as mat;

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`


$intervare-app-typography: mat.m2-define-typography-config(
    $font-family: '#{$poppins}',
    $headline-1: null,
    $headline-2: null,
    $headline-3: null,
    $headline-4: null,
    $headline-5: null,
    $headline-6: null,
    $subtitle-1: null,
    $subtitle-2: null,
    $body-1: mat.m2-define-typography-level(14px, 20px, 500, $poppins, normal),
    $body-2: mat.m2-define-typography-level(14px),
    $caption: null,
    $button: mat.m2-define-typography-level(14px),
    $overline: null,
);
@include mat.typography-hierarchy($intervare-app-typography);
@include mat.all-component-typographies($intervare-app-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$intervare-primary: (
    100: lighten($color-dark-green, 10%),
    500: $color-dark-green,
    700: darken($color-dark-green, 10%),
    contrast: (
        100: $text-contrast,
        500: $text-contrast,
        700: $text-contrast,
    )
);
$intervare-accent: (
    100: lighten($color-orange, 10%),
    500: $color-orange,
    700: darken($color-orange, 10%),
    contrast: (
        100: $text-contrast,
        500: $text-contrast,
        700: $text-contrast,
    )
);
$intervare-warn: (
    100: lighten($color-error, 10%),
    500: $color-error,
    700: darken($color-error, 10%),
    contrast: (
        100: $text-contrast,
        500: $text-contrast,
        700: $text-contrast,
    )
);
$intervare-alt-primary: (
    100: lighten($color-green, 10%),
    500: $color-green,
    700: darken($color-green, 10%),
    contrast: (
        100: $text-contrast,
        500: $text-contrast,
        700: $text-contrast,
    )
);
$intervare-alt-accent: (
    100: lighten($color-search-grey, 10%),
    500: $color-search-grey,
    700: darken($color-search-grey, 10%),
    contrast: (
        100: $text,
        500: $text,
        700: $text,
    ),
);
$intervare-app-primary: mat.m2-define-palette($intervare-primary);
$intervare-app-accent: mat.m2-define-palette($intervare-accent);
$intervare-app-alt-primary: mat.m2-define-palette($intervare-alt-primary);
$intervare-app-alt-accent: mat.m2-define-palette($intervare-alt-accent);
$intervare-app-tab-color: $color-black;
$intervare-app-tab-color--active: $color-orange;

// The warn palette is optional (defaults to red).
$intervare-app-warn: mat.m2-define-palette($intervare-warn);

// Create the theme object (a Sass map containing all of the palettes).
$intervare-app-theme: mat.m2-define-light-theme((
    color: (
        primary: $intervare-app-primary,
        accent: $intervare-app-accent,
        warn: $intervare-app-warn,
    ),
    typography: $intervare-app-typography,
    density: 0,
));

$intervare-app-alt-theme: mat.m2-define-light-theme((
    color: (
        primary: $intervare-app-alt-primary,
        accent: $intervare-app-alt-accent
    ),
    typography: $intervare-app-typography,
    density: -2,
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($intervare-app-theme);
@include mat.button-density(0);
@include mat.paginator-density(-2);

@include mat.datepicker-base($intervare-app-theme);
@include mat.form-field-base($intervare-app-theme);
@include mat.icon-base($intervare-app-theme);
@include mat.icon-button-base($intervare-app-theme);
@include mat.radio-base($intervare-app-theme);
@include mat.select-base($intervare-app-theme);

.alt-theme {
    @include mat.all-component-colors($intervare-app-alt-theme);
    @include mat.button-density(0);

    @include mat.datepicker-base($intervare-app-alt-theme);
    @include mat.form-field-base($intervare-app-alt-theme);
    @include mat.icon-base($intervare-app-alt-theme);
    @include mat.icon-button-base($intervare-app-alt-theme);
    @include mat.radio-base($intervare-app-alt-theme);
    @include mat.select-base($intervare-app-alt-theme);
}

.mat-mdc-form-field-wrapper {
    padding-bottom: px-to-rem(16);
}

// end of the new structure for the material design

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-raised-button {
    @include no-outline;
    border-radius: 50px !important;
    box-shadow: none !important;

    .mat-ripple {
        border-radius: 50px !important;
        z-index: 1; // Bugfix for older WebKit browsers not respecting border radius for overflow
    }
}

.mat-mdc-fab {
    @include no-outline;
    width: 50px !important;
    height: 50px !important;
    box-shadow: none !important;

    .mdc-button__label {
        padding: 13px 0 !important;
        min-height: 50px;
        display: inline-flex !important;
        align-items: center;
    }
}

.mat-mdc-mini-fab {
    box-shadow: none !important;

    .mdc-button__label {
        min-height: 40px;
        display: inline-flex !important;
        align-items: center;
    }
}

.mat-mdc-tooltip {
    white-space: pre-line;
}

mat-row {
    &.hover:hover {
        background: rgba(0, 0, 0, 0.04);
        cursor: pointer;
    }
}

.mat-mdc-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
        white-space: normal !important;
    }
}

.mat-mdc-table {
    &__shade {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 56px;
        right: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.15);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    }
}

.mat-mdc-cell {
    line-height: normal;
}

.cdk-header-cell {
    font-size: 12px;
    line-height: 14px;
    color: $color-key-grey;
    font-weight: 500;
}

.mat-step-header {
    & .mat-step-icon {
        @extend .icon;
        display: flex;
    }
}

@include respond-to(mobile) {
    .mat-datepicker-popup {
        top: 50% !important;
        bottom: auto !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        margin: 0 !important;
    }
}

.mat-mdc-table .mat-mdc-row__highlight {
    font-weight: bold;
}
