.flip {
    perspective: 800;
    position: relative;

    .card {

        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: 0.5s;

        &.flipped {
            transform: rotatex(-180deg);

            &.sideways {
                transform: rotatey(-180deg);
            }
        }

        .face {
            width: 100%;
            height: 100%;
            position: absolute;
            backface-visibility: hidden;
            z-index: 2;
        }

        .front {
            position: absolute;
            z-index: 1;
        }

        .back {
            transform: rotatex(-180deg);
        }

        &.sideways .back {
            transform: rotatey(-180deg);
        }
    }
}
