@use "sass:math";
$administration-form-elements-margin: 20px;

.administration-delivery-date {

    &__wrapper {
        display: flex;
    }

    &__form,
    &__info-section {
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 50%;
        align-items: center;
        margin-bottom: px-to-rem($administration-form-elements-margin * 2);

        @include respond-to(small-desktop) {

        }
    }

    &__info-section {
        align-items: flex-start;
        flex-direction: row;
        max-width: 100%;

        @include respond-to(small-desktop) {
            flex-direction: column;
            min-width: auto;
        }
    }

    &__column {
        margin-right: px-to-rem($administration-form-elements-margin);

        @include respond-to(small-desktop) {
            width: 100%;
        }
    }

    &__history {
        margin-top: px-to-rem($administration-form-elements-margin);
    }

    &__info {
        margin-left: px-to-rem($administration-form-elements-margin);
        flex: 1 0 25%;
        text-align: justify;
        padding: 0 px-to-rem(math.div($administration-form-elements-margin, 2));
    }

    .mat-mdc-form-field {
        width: 100%;
    }

    &__btn {
        flex: 1 0 100%;
        align-self: flex-start;
    }
}

iv-administration-history-delivery-date {
    display: block;
    padding-top: px-to-rem($administration-form-elements-margin);
}

.administration-history-delivery-date {
    margin-top: px-to-rem($administration-form-elements-margin);

    &__form {
        display: flex;
        padding-top: px-to-rem($administration-form-elements-margin);
    }

    &__to,
    &__from {
        margin-right: px-to-rem($administration-form-elements-margin);
    }

    &__data {
        padding-top: px-to-rem($administration-form-elements-margin);
    }

}

.administration-delivery-date,
.administration-history-delivery-date {

    .delivery-list-item__even {
        background-color: rgba($color-light-grey, .3);
    }

    .mat-expansion-panel-header-title,
    .mat-expansion-panel-header-description {
        flex: 0 1 auto;
        width: 120px;
    }

    .mat-expansion-panel-header-description {
        justify-content: space-between;
        align-items: center;
        width: auto;
    }

    .mat-expansion-panel .mat-content {
        flex-direction: row !important;
    }

    .mat-expansion-panel  {
        .mat-expansion-panel-header {
            border-bottom: 1px solid $color-divider;
            border-top: none;
        }

        &:first-child .mat-expansion-panel-header {
            border-top: 1px solid $color-divider;
        }
    }

    .mat-expansion-panel .mat-expansion-panel-content {
        border-top: none;
    }

    .mat-mdc-table {
        background-color: transparent;
    }

    .cdk-column-Edit,
    .cdk-column-Delete {
        padding-right: px-to-rem(math.div($administration-form-elements-margin, 2));
        padding-left: px-to-rem(math.div($administration-form-elements-margin, 2));
    }

    .align-center {
        text-align: center;
    }

    .align-right {
        text-align: right;
    }
}
