@use "sass:math";

$municipality-temporary-address: $padding-small;
$municipality-temporary-address-check: $color-white;

.municipality-temporary-address {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    &__form {
        @include row(10);
        width: 100%;
        margin-bottom: $municipality-temporary-address;
    }

    &__form-container {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    &__activate {
        float: right;
    }

    &__deactivate {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &-text {
            text-align: center;
            margin-bottom: $municipality-temporary-address;
        }
    }

    &__alert {
        position: relative;
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .svg-check {
            fill: $municipality-temporary-address-check;
            width: 21px;
            height: 21px;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    iv-form-builder-element {
        @include col(16);
    }

    .form-builder-element {
        &__StartDate,
        &__EndDate,
        &__DeliveryDay,
        &__OrderDay,
        &__BiWeeklyDeliveryDay,
        &__BiWeeklyOrderDay {
            flex-basis: 50%;
            max-width: 50%;
        }

        &__StreetNumber,
        &__AddressFloor,
        &__AddressSide {
            flex-basis: math.div(1, 3) * 100%;
            max-width: math.div(1, 3) * 100%;
        }

        &__City {
            .form-builder-elements-city {
                &__zip-code {
                    max-width: calc(1 / 3 * 100% - 5px);
                    margin-right: 5px;
                }

                &__city {
                    max-width: calc(2 / 3 * 100% - 5px);
                    margin-left: 5px;
                }
            }
        }
    }
}
