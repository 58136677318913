:root {
    --offline: rgba(108, 108, 108, .6);
    --available: #5fb11b;
    --busy: #ff0000;
    --away: #fb3;
    --break: #fb3;
    --meeting: #ff0000;
    --training: #fb3;
    --meal: #fb3;
    --on-queue: #21c0f6;
    --out-of-office: #ff1dce;
}

.caller-presence {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        // let's be sure we don't see any white pixel arround
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 50%;
        border-width: 5px;
        border-color: transparent;
        border-style: solid;

        transition: border-color ease-in-out 200ms;
    }

    &__offline {
        &:before {
            border-color: var(--offline);
        }
    }

    &__available {
        &:before {
            border-color: var(--available);
        }
    }

    &__on_queue {
        &:before {
            border-color: var(--on-queue);
        }
    }

    &__away,
    &__break,
    &__meal,
    &__training {
        &:before {
            border-color: var(--training);
        }
    }

    &__meeting,
    &__busy {
        &:before {
            border-color: var(--busy);
        }
    }

    &__idle,
    &__outofoffice {
        &:before {
            border-color: var(--out-of-office);
        }
    }

}
