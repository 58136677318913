$commerce-minibasket-item-border: $color-divider;
$commerce-minibasket-item-unit-price-color: $color-key-grey;
$commerce-minibasket-item-campaign-discount: $color-orange;
$commerce-minibasket-item-not-available-opacity: .5;

.commerce-minibasket-item {
    @include row;
    position: relative;
    overflow-x: hidden;
    border-bottom: 1px solid $commerce-minibasket-item-border;
    padding: 20px 0 19px;
    align-items: center;

    &--has-warning,
    &--not-available {
        padding-bottom: 0;
    }

    iv-commerce-minibasket-item:last-child & {
        margin-bottom: 15px;
        border-bottom: none;
    }

    &__link {
        flex-basis: 40px;
        margin-right: 5px;

        &--not-available {
            opacity: $commerce-minibasket-item-not-available-opacity;
        }

    }

    &__info {
        @include col;
        @include truncate-text;

        &--not-available {
            opacity: $commerce-minibasket-item-not-available-opacity;
        }
    }

    &__name {
        @include truncate-text;
        font-weight: 500;
        font-size: 14px;
    }

    &__prices {
        @include row;
        font-size: 12px;
    }

    &__price {
        margin-right: 10px;

        &_campaign-discount {
            color: $commerce-minibasket-item-campaign-discount;
        }
    }

    &__unit-price {
        color: $commerce-minibasket-item-unit-price-color;
    }

    &__addtobasket {
        flex-basis: 60px;
        max-width: 60px;
    }
}
