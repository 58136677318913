.dialog-options {
    &__content {
        overflow: hidden;
        &__small {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    &__radios {
        display: inline-flex;
        flex-direction: column;
        margin: .5rem 1rem;
    }

    &__radio {
        margin: .5rem 0;
    }

    .mdc-label{
        line-height: 20px;
    }
}
