@use "sass:math";

$municipality-subscribe-citizen-padding: $padding-small;
$municipality-subscribe-citizen-check: $color-white;

.municipality-subscribe-citizen {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__form {
        @include row(10);
        width: 100%;
        margin-bottom: $municipality-subscribe-citizen-padding;
    }

    &__form-step {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
    }

    &__next {
        margin-left: auto !important;
    }

    &__alert {
        position: relative;
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .svg-check {
            fill: $municipality-subscribe-citizen-check;
            width: 21px;
            height: 21px;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__payment {
        padding-bottom: 4rem;
    }

    iv-form-builder-element {
        @include col(16);
    }

    .form-builder-element {
        &__StreetNumber,
        &__AddressFloor,
        &__AddressSide,
        &__Gender {
            flex-basis: math.div(1, 3) * 100%;
            max-width: math.div(1, 3) * 100%;
        }

        &__CPR {
            flex-basis: math.div(2, 3) * 100%;
            max-width: math.div(2, 3) * 100%;
        }

        &__PhoneNumber,
        &__MobileNumber,
        &__DeliveryDay,
        &__OrderDay,
        &__BiWeeklyDeliveryDay,
        &__BiWeeklyOrderDay {
            flex-basis: 50%;
            max-width: 50%;
        }

        &__City {
            .form-builder-elements-city {
                &__zip-code {
                    max-width: calc(1 / 3 * 100% - 5px);
                    margin-right: 5px;
                }

                &__city {
                    max-width: calc(2 / 3 * 100% - 5px);
                    margin-left: 5px;
                }
            }
        }
    }

    .mat-horizontal-content-container {
        padding: $municipality-subscribe-citizen-padding 0 !important;
    }

    .mat-step-label {
        font-size: 18px;
    }

    .mat-mdc-table {
        margin-bottom: $municipality-subscribe-citizen-padding;
    }

    .mat-step-header {
        user-select: none;

        &:last-child {
            pointer-events: none;
        }

        .mat-step-icon {

            align-items: center;
            justify-content: center;

            .svg-icon {
                height: 15px;
                width: 15px;
                fill: white;
            }
        }
    }
}
