$municipality-padding: $padding-small;

.municipality-citizen-overview {

    &__loading {
        display: flex;

        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 100%;
    }

    &__table {
        margin-top: $municipality-padding;
        text-align: right;

        mat-header-cell,
        mat-cell {

            text-align: center;

            &:nth-child(1) {
                text-align: left;
            }

        }
    }

    &__loading,
    &__results-empty {
        padding: $municipality-padding 0;
    }

}
