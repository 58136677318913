.invoice {
    padding: 1rem;

    @include respond-to(tablet, desktop) {
        padding: 0;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;

        @include respond-to(tablet, desktop) {
            flex-direction: row;
        }
    }
}