@use "sass:math";

$close-button-background-color: $color-black;
$close-button-icon-color: $color-white;

.dialog {
    position: relative;

    &__close-button {
        position: absolute !important;
        top: -24px !important;;
        right: -24px !important;;
        background: $close-button-background-color !important;;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            $size: 50%;
            max-width: $size;
            max-height: $size;
            fill: $close-button-icon-color;
        }
    }


    &__actions {
        > button,
        > iv-progress-button {
            margin-left: .5rem;
        }
    }
}

// Overwriting/adding to Materials
.cdk-overlay {
    &-pane {
        &.fullscreen_mobile {
            @include respond-to(mobile) {
                position: fixed !important;
                top: 0;
                left: 0;
                z-index: map-get($z-layers, top);
                width: 100%;
                height: 100%;
                margin: 0;

                .dialog {
                    &__close-button {
                        position: absolute;
                        left: auto;
                        bottom: auto;
                        top: 1rem !important;
                        right: 1rem !important;
                    }
                }

                .cdk-dialog-container {
                    overflow: auto;
                }

                .cdk-dialog-container {
                    max-height: 100%;
                    padding-bottom: 1rem;
                }
            }
        }

        &.nomargin {
            .cdk-dialog-container {
                padding: 0;
                margin: 0;
            }
        }

        &.auto-scroll {
            overflow: auto;
            max-height: 95vh;
        }
    }

    &-container {
        .cdk-dialog-container {
            overflow: visible;
        }
    }
}
