$color-white: #ffffff;
$color-black: #000000;

$color-orange: #d4793a;

$color-dark-green: #57634b;

$color-green: #85977d;

$color-dark-grey: #2b3441;
$color-grey: #354052;
$color-light-grey: #F6F7F8;
$color-grey-input: #E1E5EB;
$color-grey-link: #888;

$color-search-grey: #E1E5EB;
$color-key-grey: #9B9B9B;

$color-border-grey: #e7e7e7;

$color-divider: #D9DADB;

$color-error: #D24C47;

$color-alert-info: #E9B649;
$color-alert-success: $color-dark-green;
$color-alert-danger: $color-error;

$text: $color-black;
$text-half: #8F8F8F;
$text-contrast: $color-white;
$text-alt: #95a1b4;

$padding: 30px;
$padding-small: calc($padding / 2);

$header-top-height: 75px;
$header-bottom-height: 81px;
$header-height: $header-top-height+$header-bottom-height;
$header-impersonated-height: $header-height+20px;

$header-top-height-device: 51px;
$header-bottom-height-device: 51px;
$header-height-device: $header-top-height-device+$header-bottom-height-device;
$header-impersonated-height-device: $header-height-device+20px;

$product-list-image-size-desktop: 160px;
$product-list-image-size-tablet: 100px;
$product-list-image-size-mobile: 60px;

$basket-item-image-size-desktop: 105px;
$basket-item-image-size-device: 70px;

$minibasket-width: 300px;

$z-layers: ( "top": 6,
"over-middle": 5,
"middle": 4,
"over-bottom": 3,
"bottom": 2,
"default": 1);

@function z($layer) {
    @return map-get($z-layers, $layer);
}
