$commerce-basket-border: $color-divider;
$commerce-basket-grey: $color-key-grey;
$commerce-basket-spacing: $padding;
$commerce-basket-spacing-small: $padding-small;
$commerce-basket-highlight: $color-orange;

.commerce-basket {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: $commerce-basket-spacing;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__head {
        border-top: 1px solid $commerce-basket-border;
        border-bottom: 1px solid $commerce-basket-border;
        color: $commerce-basket-grey;
        padding: 10px 0;
        font-size: 12px;
        line-height: 12px;
    }

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__image {
        text-align: center;
    }

    &__prices {
        text-align: right;
    }

    &__total-row-price {
        text-align: right;
    }

    &__addtobasket {
        text-align: center;
    }

    &__sub-head {
        border-bottom: 1px solid $commerce-basket-border;
        padding-bottom: $commerce-basket-spacing-small;
        margin-bottom: 0;
    }

    &__items {
        margin-bottom: $commerce-basket-spacing-small;
    }

    &__info {
        @include row;
        padding-bottom: $commerce-basket-spacing-small;
        margin-bottom: $commerce-basket-spacing;
        border-bottom: 1px solid $commerce-basket-border;
    }

    &__clear-basket {
        .mat-mdc-button {
            padding: 0 $commerce-basket-spacing-small !important;
        }

        .mat-mdc-button,
        .mat-ripple {
            border-radius: 0 !important;
        }

        @include respond-to(mobile) {
            order: 1;
            width: 100%;
            margin-top: 5px;

            .mat-mdc-button {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__summary {
        @include offset(9, 6);
        display: block;
        width: 100%;

        @include respond-to(tablet, desktop) {
            flex: 1;
        }
    }

    &__date-picker {
        padding: 0 $commerce-basket-spacing-small;
        display: flex;
        margin-top: $commerce-basket-spacing-small * 2;
        margin-bottom: $commerce-basket-spacing-small;
    }

    &__preferred-delivery {
        margin-left: $commerce-basket-spacing-small;

        &-text {
            color: rgba(0, 0, 0, 0.54);
            font-size: 10px;
            line-height: 10px;
        }

        &-btn {
            margin-right: 5px !important;
            margin-top: 4px !important;
            line-height: 25px !important;

            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    &__radio-group {
        padding: 0 $commerce-basket-spacing-small;
        margin-bottom: $commerce-basket-spacing;
    }

    &__radio {
        margin-right: $commerce-basket-spacing-small;

        @include respond-to(mobile) {
            display: block;
            margin-right: 0;
            width: 100%;
            margin-bottom: 5px;
        }
    }

    &__checkbox {
        margin-right: $commerce-basket-spacing-small;

        @include respond-to(mobile) {
            display: block;
            margin-right: 0;
            width: 100%;
            margin-bottom: 5px;
        }
    }

    &__week-offer {
        margin-top: $commerce-basket-spacing-small * 2;
        margin-bottom: $commerce-basket-spacing * 2;

        &_is-admin {
            order: -1;
        }

        @include respond-to(mobile) {
            margin-bottom: $commerce-basket-spacing-small;
        }
    }

    &__checkout {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: $commerce-basket-spacing * 2;

        @include respond-to(mobile) {
            flex-direction: column;
            margin-bottom: $commerce-basket-spacing-small;
        }
    }

    &__checkout-comment {
        margin: 0 15px !important;
    }

    &__checkout-btn {
        min-width: 300px !important;

        @include respond-to(mobile) {
            width: 100%;
            min-width: 0 !important;
            margin-bottom: 5px !important;
            margin-top: 10px !important;
        }
    }

    &__error {
        margin-right: $commerce-basket-spacing-small;
    }

    &__max-buy {
        display: flex;
        justify-content: flex-end;
        margin-bottom: $commerce-basket-spacing-small;

        &-alert {
            min-width: 300px;
            text-align: center;
            margin-bottom: 0 !important;

            @include respond-to(mobile) {
                min-width: 0;
                width: 100%;
            }
        }
    }

    &__delivery {
        display: flex;
        justify-content: flex-end;
        margin-bottom: $commerce-basket-spacing-small;

        &-wrap {
            display: flex;
            justify-content: space-between;
            min-width: 300px;

            @include respond-to(mobile) {
                min-width: 0;
                width: 100%;
            }
        }

        &-text {
            color: $commerce-basket-grey;
            font-weight: 500;
        }
    }
}
