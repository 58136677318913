$commerce-product-list-item-border: $color-divider;
$commerce-product-list-item-grey-text: $color-grey-link;
$commerce-product-list-item-discount-color: $color-orange;
$commerce-product-list-item-replacement-background: $color-white;
$commerce-product-list-item-padding: 5px;

$commerce-product-list-item-size-desktop: $product-list-image-size-desktop;
$commerce-product-list-item-size-tablet: $product-list-image-size-tablet;
$commerce-product-list-item-size-mobile: $product-list-image-size-mobile;

.commerce-product-list-item {
    position: relative;
    border-bottom: 1px solid $commerce-product-list-item-border;
    padding: 5px 0;

    &__no-product {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 16px;
    }

    &__mat-progress-spinner {
        margin: 66px auto;
    }

    &_skeleton {
        @include respond-to(desktop) {
            height: $commerce-product-list-item-size-desktop+$commerce-product-list-item-padding * 2+1px;
        }

        @include respond-to(tablet) {
            height: $commerce-product-list-item-size-tablet+$commerce-product-list-item-padding * 2+1px;
        }

        @include respond-to(mobile) {
            height: $commerce-product-list-item-size-mobile+$commerce-product-list-item-padding * 2+1px;
        }

        .commerce-product-list-item__description {
            display: none;
        }
    }

    &_soldout {
        opacity: 0.5;
    }

    &_replacement {
        position: relative;

        & {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 100%;
                z-index: 2;
                background-color: $commerce-product-list-item-border;
            }
        }
    }

    &__replacement-text {
        position: absolute;
        top: -6px;
        left: 5px;
        line-height: 12px;
        font-size: 12px;
        padding: 0 10px;
        background-color: $commerce-product-list-item-replacement-background;
        z-index: 3;
        color: $commerce-product-list-item-grey-text;
        user-select: none;
    }

    &__image {
        width: 100%;
        height: auto;
    }

    &__content {
        padding: 20px 0;

        @include respond-to(desktop) {
            min-height: $commerce-product-list-item-size-desktop;
        }

        @include respond-to(tablet) {
            min-height: $commerce-product-list-item-size-tablet;
        }

        @include respond-to(mobile) {
            min-height: $commerce-product-list-item-size-mobile;
            padding: 5px 0;
        }
    }

    &__id {
        color: $commerce-product-list-item-grey-text;
        font-size: 16px;
    }

    &__favorite {
        @include when-inside('.commerce-product-list-item_skeleton') {
            display: none;
        }
    }

    &__prices {
        text-align: right;

        &_mobile {
            @include respond-to(tablet, desktop) {
                display: none;
            }

            @include respond-to(mobile) {
                display: flex;
                flex-wrap: wrap;
                font-weight: 500;
                margin: 5px 0;
            }
        }
    }

    &__info {
        @include respond-to(tablet, desktop) {
            @include truncate-text;
        }
    }

    &__name {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;

        @include respond-to(tablet, desktop) {
            @include truncate-text;
        }
    }

    &__description {
        color: $commerce-product-list-item-grey-text;

        @include respond-to(tablet, desktop) {
            @include truncate-text;
        }
    }

    &__discount-price {
        color: $commerce-product-list-item-discount-color;
        font-size: 16px;
        line-height: 20px;

        @include respond-to(mobile) {
            margin-right: 5px;
        }
    }

    &__price {
        font-size: 16px;
        line-height: 20px;

        &_line-through {
            text-decoration: line-through;
            color: $commerce-product-list-item-grey-text;
            line-height: 18px;
            font-size: inherit;

            @include respond-to(mobile) {
                line-height: 20px;
            }
        }
    }

    &__unit-price {
        color: $commerce-product-list-item-grey-text;
        line-height: 18px;

        &_mobile {
            display: none;

            @include respond-to(mobile) {
                display: inline;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include respond-to(mobile) {
            margin-top: 5px;
        }
    }

    &__labels {
        .commerce-labels {
            margin-top: 5px;
        }

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__chips {
        margin-top: 5px;
    }

    &__chip {
        margin: 0 10px 5px 0 !important;

        &:last-child {
            margin: 0 0 5px 0 !important;
        }
    }

    &__spinner {
        display: flex;
        justify-content: center;
    }
}
