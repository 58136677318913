.login {
    &__content {
        position: relative;
        width: 40%;
        margin: 0 auto;

        @include respond-to(tablet) {
            width: 60%;
        }

        @include respond-to(mobile) {
            width: 100%;
        }

        .mat-mdc-form-field {
            width: 100%;
        }
    }

    &__forgot-password {
        .svg-icon {
            height: 75px;
        }
    }

    &__forgot-password-reset {
        padding-top: 2rem;

        > p {
            padding-left: 40px;
        }
    }

    &__go-back {
        position: absolute;
        top: 0;
        left: 0;
        color: black;
    }

    &__btn {
        > button {
            display: block;
            width: 100%;
        }
    }
}

iv-user-edit-password {
    .edit-password {
        .mat-mdc-form-field {
            margin-bottom: 20px;
        }
    }
}
