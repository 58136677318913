$layout-navigation-device-background: $color-white;
$layout-navigation-device-border: $color-divider;
$layout-navigation-device-item-bottom-background: $color-grey-input;
$layout-navigation-device-active: $color-orange;
$layout-navigation-device-head-height: $header-top-height-device - 1px; // Minus 1px for border
$layout-navigation-device-item-height: $layout-navigation-device-head-height - 10px;

.layout-navigation-device {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z('top');

    &__navigation {
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: 80%;
        max-width: 500px;
        height: 100vh;
        background-color: $layout-navigation-device-background;
    }

    &__level {
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: $layout-navigation-device-background;
        z-index: 1;

        &_first {
            transform: translateX(0);
        }
    }

    &__overlay {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(black, 0.4);
        width: 100vw;
        height: 100vh;
        cursor: pointer;
    }

    &__head {
        display: flex;
        border-bottom: 1px solid $layout-navigation-device-border;
        align-items: center;
    }

    &__close {
        border-radius: 0 !important;
        padding: 0 !important;
        min-width: $layout-navigation-device-head-height !important;

        .mat-ripple {
            border-radius: 0 !important;
        }

        .svg-icon {
            height: 15px;
            width: 15px;
        }
    }

    &__item {
        @include truncate-text;
        width: 100%;
        align-items: center !important;
        justify-content: flex-start !important;
        text-align: left !important;
        border-radius: 0 !important;
        padding: 0 10px !important;
        border-bottom: 1px solid $layout-navigation-device-border !important;

        &_head {
            display: block !important;
            line-height: $layout-navigation-device-head-height !important;
            font-size: 18px;
            width: auto;
            flex: 1;
            height: auto !important;
            border-bottom: none !important;
        }

        &_center {
            text-align: center !important;
            justify-content: center !important;
        }

        .mat-ripple {
            border-radius: 0 !important;
        }

        @include when-inside('.category-menu-level-device') {
            border-bottom: 1px solid $layout-navigation-device-border;
        }

        &_logout {
            background-color: $layout-navigation-device-item-bottom-background;
            border-top: 1px solid $layout-navigation-device-border;
        }

        &_active {
            color: $layout-navigation-device-active !important;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 5px;
                background-color: $layout-navigation-device-active !important;
            }

            .layout-navigation-device__item-icon {
                fill: $layout-navigation-device-active;
            }
        }

        &_has-children {
            padding: 0 $layout-navigation-device-head-height 0 10px !important;
        }
    }

    &__back,
    &__forward {
        position: absolute !important;
        transform: translateY(-50%);
        top: 50%;

        .svg-icon {
            width: 15px;
            height: 15px;
        }
    }

    &__back {
        left: 6px;
    }

    &__forward {
        right: 6px;
    }
}
