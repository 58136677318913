$history-detail-border: 1px solid rgba(0,0,0,.2);
$history-item-padding: $padding;
$history-item-color-grey: $color-key-grey;
$history-item-accent-color: $color-orange;

.history-list {
    @include ul-reset;

    li {
        border-top: $history-detail-border;

        &:first-child {
            border: none;
        }
    }
}

.history-item {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding-top: $history-item-padding*0.5;
    padding-left: 40px;

    svg {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        max-width: 24px;

        &.svg-note {
            max-width: 16px;
        }
        &.svg-cart {
            max-width: 20px;
        }
    }

    > h4 {
        flex: 1;
        order: 1;
    }

    &__link {
        margin-right: 5rem;
        order: 2;
        color: $history-item-accent-color;

        @include when-inside('.citizen-summary__history') {
            margin: 0;
        }
    }

    &__data-and-user {
        flex: 1 100%;
        order: 3;
        color: $history-item-color-grey;
        font-weight: map-get($font-weights, base);
    }

    @include when-inside('.citizen-details') {
        padding-top: .25rem;

        &__data-and-user {
            margin-bottom: .25rem;
        }
    }
}
