$administration-weeklyoffers-border-color: $color-border-grey;


.administration-weeklyoffers {

    &__form {
        padding-bottom: 2rem;
        border-bottom: 1px solid $administration-weeklyoffers-border-color;
        margin-bottom: 2rem;
    }

    &__weeknos {
        width: 140px;
    }
}
