.commerce-labels {
    &__label {

        .svg-icon {
            display: block;
            float: left;
            height: 20px;
            max-width: 30px;
            margin-right: 5px;
        }
    }
}
