$page-homecarer-spacing: $app-padding-small;
$page-homecarer-spacing-small: 10px;
$page-homecarer-border: $color-divider;
$page-homecarer-check: $color-dark-green;
$page-homecarer-height: 200px;

.page-homecarer {

    &__section-header {
        margin-top: $page-homecarer-spacing * 2;
        margin-bottom: $page-homecarer-spacing;
    }

    &__status {
        display: flex;
        height: $page-homecarer-height;
        align-items: center;
        justify-content: center;
    }

    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $page-homecarer-height;
    }

    &__select-group {
        border-top: 1px solid $page-homecarer-border;
        padding-top: $page-homecarer-spacing;
        margin-bottom: $page-homecarer-spacing;
    }

    &__select-form-field {
        display: block !important;
        margin-bottom: 0 !important;
    }

    &__citizens {
        border-top: 1px solid $page-homecarer-border;
        margin-top: $page-homecarer-spacing;
        margin-bottom: $page-homecarer-spacing * 2;
    }

    &__citizen {
        border-bottom: 1px solid $page-homecarer-border;
        padding: $page-homecarer-spacing-small 0;

        &_impersonated {
            margin: -1px 0;
            border-bottom: 2px solid $color-green;
            border-top: 2px solid $color-green;
        }
    }

    &__citizen-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 69px;
    }

    &__info {
        @include truncate-text;

        @include when-inside('.page-homecarer__citizen_inactive') {
            opacity: .5;
        }
    }

    &__controls {
        min-width: 190px;
        display: flex;
        justify-content: flex-end;

        @include when-inside('.page-homecarer__citizen_inactive') {
            opacity: .5;
        }
    }

    &__name {
        @include truncate-text;
        margin-bottom: 0;
    }

    &__address {
        @include truncate-text;
    }

    &__transfer {
        margin-right: 10px !important;
    }

    &__transferred {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-text {
            display: flex;

            &>* {
                padding-left: 5px;
            }
        }
    }

    &__blocked {
        color: $color-error;

        @include respond-to(mobile) {
            text-align: center;
        }
    }

    &__delivery-changed {
        color: $color-error;
        &--mobile {
            @include respond-to(tablet, desktop) {
                display: none;
            }
        }
        &--desktop {
            @include respond-to(mobile) {
                display: none;
            }
        }
    }
}
