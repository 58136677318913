.administration-view-users {

    &__clicked {
        background-color: $color-light-grey;
    }

    &__pagination {
        display: flex;
        align-items: center;
    }

    &__form,
    &__form-group {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    &__form-group {
        gap: 0;
        flex: 1 1 60%;
        max-width: 60%;

        &--vertical {
            flex-direction: column;
        }
    }

    &__form-element {
        width: 100%;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex: 1 0 40%;
        width: 40%;
        margin: 10px 0 10px 20px;
    }

    &__fetching {
        flex: 1 0 auto;
    }

    &__feedback {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        flex: 1 0 auto;
    }

    .cdk-column-userId {
        width: 90px;
        flex: 0 1 auto;
    }

    .cdk-column-inactive {
        flex: 0 0 90px;
        width: 90px;
        justify-content: center;
    }

    .cdk-column-actions {
        justify-content: flex-end;
        padding-right: 0;
        width: 120px;
        flex: 0 0 120px;
    }

    &__is-deleted {
        width: 20px;
        height: 20px;
    }

    &__change-username {
        svg path {
            fill: $color-black;
        }
    }
}
