$poppins: "Poppins", sans-serif;

$font-families: (
    base: ($poppins),
    headline: ($poppins),
);

$font-sizes: (
    xsmall: 10px,
    small: 12px,
    base: 14px,
    large: 18px,
    larger: 25px,
    largest: 40px,
);

$font-weights: (
    base: 400,
    headline: 500,
    bold: 600,
);

$line-heights: (
    base: 1.5
);

@mixin typeset(
    $size: base,
    $weight: base,
    $family: base,
    $line-height: base
) {
    font-size: px-to-rem(map-get($font-sizes, $size));
    font-weight: map-get($font-weights, $weight);
    font-family: map-get($font-families, $family);
    font-style: normal;
    $line-height: map-get($line-heights, $line-height);
    line-height: if(unit($line-height) == '', $line-height, px-to-rem($line-height));
}

@mixin primary-heading {
    @include typeset(largest, headline, headline);
}

@mixin secondary-heading {
    @include typeset(larger, headline, headline);
}

@mixin tertiary-heading {
    @include typeset(large, headline, headline);
}

@mixin body-copy {
    @include typeset();
}

@mixin body-copy-bold {
    @include typeset(base, bold);
}

@mixin body-copy-small {
    @include typeset(small);
}

html {
    font-size: 87.5%; // 14px, same as base
}

body {
    font-family: map-get($font-families, base);
    font-weight: map-get($font-weights, base);
    line-height: map-get($line-heights, base);
    color: $text;
}

p {
    @include body-copy();
}

b, strong {
    @include body-copy-bold();
}

small {
    @include body-copy-small();
}

p,
.content {
    a, a:hover, a:focus, a:visited {
        color: $color-grey-link;
        text-decoration: underline;
    }

    a:hover{
        text-decoration: none;
    }
}

a:hover{
    color: inherit;
    text-decoration: none;
}

h1 {
    @include primary-heading();
}

h2 {
    @include secondary-heading();
}

h3 {
    @include tertiary-heading();
    margin-bottom: .7rem;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-divider;
    margin: 1em 0;
    padding: 0;
}

dl {
    dt {
        @include body-copy;
        color: $color-key-grey;
    }

    dt, dd {
        &.strong {
            @include body-copy-bold;
            color: $text;
        }

        &.warn {
            color: $color-error;
        }
    }

    &.side-by-side {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin: 0;

        dt, dd {
            margin: 0 0 .25rem;
            padding: 0;
        }

        dt {
            width: 40%;
            user-select: none;
        }

        dd {
            width: 60%;
            padding-left: 1rem;
        }
    }
}

.justify {
    text-align: justify;
}

.underline {
    text-decoration: underline;
}

.error {
    color: $color-error;
}
