$medium-editor-border-color: $color-border-grey;

.medium-editor {
    @include no-outline;

    flex: 1;
    min-height: 20rem;

    margin-left: 1px;
    padding: 1rem;

    border: 1px solid $medium-editor-border-color;
}
