// Truncate text with ellipsis
@mixin truncate-text($ellipsis: true) {
	white-space: nowrap;
	overflow: hidden;

	@if ($ellipsis == true) {
		text-overflow: ellipsis;
	}
}

@mixin when-inside ($contexts...) {
    @each $context in $contexts {
        #{$context} & {
            @content;
        }
    }
}

@mixin ul-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin no-outline() {
    &:focus {
        outline: 0;
    }

    &::-moz-focus-inner {
        border: 0;
    }
}
