$citizen-search-field-background: $color-search-grey;
$citizen-search-field-size: 30px;
$citizen-search-field-size-mobile: 20px;

.citizen-search-field {
    position: relative;

    &__input {
        @include truncate-text;
        width: 100%;
        border-radius: 50px;
        background-color: $citizen-search-field-background;
        line-height: $citizen-search-field-size;
        padding: 10px 50px 10px $citizen-search-field-size;

        @include respond-to(mobile, tablet) {
            line-height: $citizen-search-field-size-mobile;
            padding: 10px 40px 10px $citizen-search-field-size-mobile;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        position: absolute;
        right: $citizen-search-field-size;
        height: $citizen-search-field-size;
        top: 50%;
        transform: translateY(-50%);

        @include respond-to(mobile, tablet) {
            right: $citizen-search-field-size-mobile;
            height: $citizen-search-field-size-mobile;
        }

        .svg-icon {
            height: 20px;
            width: 20px;
        }
    }
}
