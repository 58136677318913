.dialog-call-status-form {
    min-width: 520px;

    .mat-mdc-dialog-content {
        max-height: 75vh !important;

    }

    .form {
        width: 100%;

        &--has-label {
            .mat-mdc-radio-group {
                margin-left: 16px;
            }
        }

    }

    &__content {
        overflow: hidden;
    }
}
