$municipality-padding: $padding-small;

.municipality-citizen-status {

    height: 100%;

    &__fetching {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__loading,
    &__results-empty {
        padding: $municipality-padding 0;
    }


    &__table-container {
        overflow: auto;
        margin-bottom: 40px;
    }

    /* Material Table */
    &__table {
        width: 800px;
        min-width: 100%;

        .mat-column-name {
            flex: 2;
        }

        .mat-column-status {
            flex: 0 1 auto;
            width: 180px;
            justify-content: center;
        }

        .cdk-row {

            .mat-column-status {
                color: $color-orange;
            }

            .mat-column-name {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-content: flex-start;
                padding: 5px 0
            }
        }

    }
}
