$alert-info-bg: $color-alert-info;
$alert-success-bg: $color-alert-success;
$alert-success-text: $color-white;
$alert-danger-bg: $color-alert-danger;
$alert-danger-text: $color-white;

.alert {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;

    &__header {
        @include body-copy-bold;
    }

    a, a:visited, a:active {
        text-decoration: underline;
    }

    a:hover {
        text-decoration: none;
    }

    /* States */
    &-info {
        background-color: $alert-info-bg;
    }

    &-success {
        background-color: $alert-success-bg;
        color: $alert-success-text;

        a, a:visited, a:active {
            color: $alert-success-text;
        }
    }

    &-danger {
        background-color: $alert-danger-bg;
        color: $alert-danger-text;

        a, a:visited, a:active {
            color: $alert-danger-text;
        }
    }
}
