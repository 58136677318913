$commerce-catalogues-spacing: $padding-small;
$commerce-catalogues-grey: $color-key-grey;

.commerce-catalogues {

    display: flex;
    flex-direction: column;
    height: 100%;

    &__head {
        color: $commerce-catalogues-grey;
    }

    &__loading,
    &__catalogues {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__catalogues {
        align-items: normal;
        justify-content: flex-start;

        &-content {
            padding: $commerce-catalogues-spacing 0;
        }
    }

    &__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        margin: 0;
        padding: 0;
        list-style: none;

        &-item {
            padding-bottom: $commerce-catalogues-spacing;
            padding-right: $commerce-catalogues-spacing;
        }
    }
}
