.municipality-create-user {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    &__form {
        width: 100%;
    }

    &__btn {
        >button {
            float: right;
        }
    }
}
