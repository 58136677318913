iv-accordion {
    display: block;
}

.accordion__title {
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    padding: 0;
    border: none;
    margin: 0;
    display: block;
    outline: none;
	text-align: inherit;
    border-radius: 0;
    width: 100%;
	font-weight: inherit;
	cursor: pointer;
}

.accordion__content {
	overflow: hidden;
}
