@use "sass:math";

$device-bps: (
    "mobile": "(max-width: 567px)",
    "tablet": "(min-width: 568px) and (max-width: 1024px)",
    "small-desktop": "(min-width: 1025px) and (max-width: 1420px)",
    "desktop": "(min-width: 1025px)",
);

@mixin respond-to($devices...) {
    @each $device in $devices {
        @if (map-has-key($device-bps, $device)) {
            $query: map-get($device-bps, $device);
            @media #{$query} {
                @content;
            }
        } @else {
            @error '`#{$device}` is not a known value in $device-bps map';
        }
    }
}

@mixin container() {
    max-width: 1200px;
    width: 100%;
    padding-left: $padding;
    padding-right: $padding;
    margin-left: auto;
    margin-right: auto;

    @include respond-to(tablet) {
        padding-left: $padding-small;
        padding-right: $padding-small;
    }

    @include respond-to(mobile) {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@mixin row($gutter: 0) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @if $gutter !=0 {
        margin-left: -$gutter * 0.5 + px;
        margin-right: -$gutter * 0.5 + px;

        > * {
            padding-left: $gutter * 0.5 + px;
            padding-right: $gutter * 0.5 + px;
        }
    }
}

@mixin col($d-cols: null, $t-cols: null, $m-cols: null) {
    @include respond-to(desktop) {
        @include create-col($d-cols);
    }

    @include respond-to(tablet) {
        @include create-col($t-cols);
    }

    @include respond-to(mobile) {
        @include create-col($m-cols, 4);
    }
}

@mixin create-col($cols, $fraction: 16) {
    @if $cols==0 {
        display: none;
    } @else if $cols {
        flex-basis: if(math.div($cols, $fraction) <=1, calc($cols / $fraction * 100%), 100%);
        max-width: if(math.div($cols, $fraction) <=1, math.div($cols, $fraction) * 100%, 100%);
    } @else {
        flex: 1;
    }
}

@mixin offset($d-cols: 0, $t-cols: 0, $m-cols: 0) {
    @include respond-to(desktop) {
        margin-left: if(
            math.div($d-cols, 16) * 100 <=100,
            calc(#{$d-cols} / 16 * 100%),
            100%
        );
    }

    @include respond-to(tablet) {
        margin-left: if(
            math.div($t-cols, 16) * 100 <=100,
            calc(#{$t-cols} / 16 * 100%),
            100%
        );
    }

    @include respond-to(mobile) {
        margin-left: if(
            $m-cols * 0.25 * 100 <=100,
            calc(#{$m-cols} / 4 * 100%),
            100%
        );
    }
}

@mixin center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
