.municipality-driver-messages {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__form {
        margin-bottom: 2rem;

        .mat-mdc-form-field {
            width: 30%;
        }

        button {
            margin-left: 1rem;
        }
    }

    &__msg-area {
        border: 1px solid $color-border-grey;

        p {
            margin: 0;
        }
    }

    &__table {
        width: 100%;

        th {
            width: calc(100% / 3);
        }

        td, th {
            padding: .5rem;
        }

        tbody {
            tr {
                border-top: 1px solid $color-border-grey;
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
    }

    &__status {
        @include center-content;
        height: 100%;
    }
}

.strikethrough {
    text-decoration: line-through;
}
