$municipality-user-list-padding: $padding-small;


.municipality-user-list {
    &__table {
        position: relative;

        .mat-column {

            &-name {
                padding-left: 0;
                flex: 1 0 5%;
            }

            &-username {
                flex: 1 0 20%;
            }

            &-district,
            &-subdistrict {
                flex: 1 0 10%;
            }
           
            &-superuser,
            &-pdauser,
            &-ring1,
            &-ring2,
            &-seddel1,
            &-inactive,
            &-seddel2 {
                flex: 1 0 40px;
                justify-content: center;
                padding: 0;
                width: 40px;
            }

            &-superuser{
                flex: 1 0 55px;
                width: 55px;

            }

            &-actions {
                flex: 1 0 120px;
                justify-content: flex-end;
                padding-right: 0;
            }
        }
    }

    &__results-empty {
        padding: $municipality-user-list-padding 0;
    }

    &__edit-username {
        svg path {
            fill: $color-black;
        }
    }

    &__form {
        display: flex;
        justify-content: space-between;
    }

    &__fetching {
        display: flex;
        flex: 1 0 auto;
        align-items: flex-start;
        justify-content: center;
    }
}

.no-margin-bottom {
    margin-bottom: 0;
}
