$card-margin: $padding-small;
$municipality-padding: $padding-small;
$table-button-padding: 3px;
$card-title-size: 24px;

.municipality-citizen-drafts {


    &__loading,
    &__fetching {
        display: flex;

        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 100%;
    }

    &__loading {
        padding: $municipality-padding 0;
    }

    &__action-button {
        padding: $table-button-padding $municipality-padding;
    }

    &__card {
        box-shadow: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;

        margin-bottom: $card-margin;

        .mat-mdc-card-title {
            font-size: $card-title-size !important;
        }
    }

    &__table {

        .mat-column-statusTo,
        .mat-column-statusFrom {
            flex: 1.5;
            text-align: center;
        }

        .mat-column-actions {
            flex: 3;
            text-align: right;
            justify-content: flex-end;
        }

        &-unsubscribe {

            .mat-column-createDate {
                flex: 2;
            }

        }

        &-notes {

            .mat-column-actions {
                flex: 1;
            }

            .mat-column-noteComment {
                flex: 2;
            }

        }

    }

    &__results-empty {
        padding: $municipality-padding 0;
    }

}
