.citizen-debtorposts {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    &__table {
        .mat-column-description {
            min-width: 40%;
        }
    }
}
