.municipality-citizen-payment {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    &__form {
        .mat-mdc-radio-group {
            flex-direction: row;
            gap: 1rem;
        }
    }
    
    &__sync-status {
        width: 100%;
    }

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__links {
        margin-bottom: 2rem;
    }
}
