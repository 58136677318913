$product-details-subtitle-color: $color-key-grey;

iv-commerce-product-details {
    display: block;
}

.product-details {
    &.mat-mdc-dialog-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        width: 940px;

        @include respond-to(mobile) {
            flex-wrap: wrap;
            width: auto;
            padding: 0 10px !important;
            max-height: 95vh;
        }
    }

    &__wrapper {
        .dialog__close-button {
            z-index: 2;
        }
    }

    &__hidden-scroll {
        flex: 1;

        @include respond-to(mobile) {
            .util-hidden-scroll {
                height: calc(100vh - 20px) !important;
            }
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: center;

        @include respond-to(mobile) {
            flex-direction: column;
            flex-wrap: wrap;
            width: auto;
        }
    }

    &__col {
        width: 50%;

        @include respond-to(desktop) {
            &:last-child {
                padding-left: 1rem;
            }
        }

        @include respond-to(mobile, tablet) {
            width: 100%;
        }
    }

    &__image-wrapper {
        position: sticky;
        top: 0;
        text-align: center;
        @include respond-to(mobile) {
            position: relative;
        }
    }

    &__image {
        max-width: 100%;
        height: auto;
    }

    &__data {
        > * {
            display: block;
            margin-bottom: 1.5rem;
        }

        h6 {
            @include tertiary-heading;
        }
    }

    &__subtitle {
        @include typeset;
        color: $product-details-subtitle-color;
    }

    &__labels {
        display: block;
        margin-bottom: 0;

        .commerce-labels {
            margin-bottom: 1.5rem;
        }
    }

    &__chips {
        display: block;
    }

    &__discount-price {
        @extend .commerce-product-list-item__discount-price;
    }

    &__price {
        @extend .commerce-product-list-item__price;

        &_line-through {
            @extend .commerce-product-list-item__price_line-through;
        }
    }

    &__unit-price {
        @extend .commerce-product-list-item__unit-price;
    }

    &__chips {
        @extend .commerce-product-list-item__chips;
    }

    &__spacer {
        @include respond-to(mobile) {
            margin-left: -2rem;
            margin-right: -2rem;
        }
    }

    &__actions-prices {
        display: block;
        position: relative;
        overflow: hidden;
        @include row;
    }

    &__prices {
        @include col(16, 16, 2);
        margin-bottom: 2rem;
    }

    &__addtobasket {
        @include col(8, 8, 2);

        .commerce-addtobasket {
            @include respond-to(desktop, tablet) {
                justify-content: flex-start;
            }
        }
    }

    &__favorites {
        @include col(null, null, 4);
        align-self: center;
    }

    &__technical-description {
        margin-bottom: 1rem;
    }

    &__declaration-label {
        span {
            word-break: break-word;
            hyphens: auto;
        }
    }

    @include respond-to(mobile) {
        &__prices {
            order: 1;
            margin: 0;
        }

        &__addtobasket {
            order: 2;
            align-self: center;
        }

        &__favorites {
            order: 3;
            margin-top: 1rem;
        }
    }
}
