@use "sass:math";

$citizen-details-menu-color: $color-key-grey;
$citizen-details-menu-color-active: $color-white;
$citizen-details-menu-background: $color-light-grey;
$citizen-details-menu-background-active: $color-dark-grey;
$citizen-details-menu-padding: 2rem;
$citizen-details-menu-background: $color-white;
$citizen-details-border-color: $color-divider;
$citizen-details-specialinformation-color: $color-orange;
$citizen-details-accent-color: $color-orange;

$citizen-summary-sync-error-color: $color-error;

.citizen-details {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;

    @include respond-to(mobile, tablet) {
        flex-wrap: wrap;
        width: 100%;
    }

    &__menu {
        width: 25%;
        background: $citizen-details-menu-background;
        display: flex;
        flex-direction: column;

        @include respond-to(mobile) {
            width: 100%;
        }

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;
            flex: 1;

            & > li {

                & > a {
                    @include body-copy-bold;
                    @include truncate-text;

                    display: block;
                    line-height: 3rem;
                    padding-left: $citizen-details-menu-padding;
                    color: $citizen-details-menu-color;

                    &:hover,
                    &.active {
                        background: $citizen-details-menu-background-active;
                        color: $citizen-details-menu-color-active;
                    }
                }
            }
        }

        &-actions {
            padding: $citizen-details-menu-padding;
            padding-bottom: 0;

            & &-button {
                display: block;
                width: 100%;
                margin-bottom: math.div($citizen-details-menu-padding, 2);

                > button {
                    width: 100%;
                }
            }
        }
    }

    &__header {
        padding: $citizen-details-menu-padding;
        padding-bottom: 7px;
        border-bottom: 1px solid $citizen-details-border-color;

        .sync-status {
            &-error {
                svg path {
                    fill: $citizen-summary-sync-error-color;
                }
            }
        }
    }

    &__subheader {
        opacity: 0.4;
    }

    &__content {
        width: 75%;
        background: $citizen-details-menu-background;
        padding: 45px 2rem 2rem;
        overflow: hidden;

        @include respond-to(mobile) {
            width: 100%;
            padding-left: 0;
        }
    }

    &__info {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding-top: 12px;

        &-container {
            width: 48%;
            padding-bottom: 3rem;
        }

        &-header {
            border-bottom: 1px solid $citizen-details-border-color;
        }

        & &-content {
            margin-top: 1rem;
        }

        &-show-specialinformation {
            color: $citizen-details-specialinformation-color;
        }
    }

    .notes {

        &__new-note {
            margin-top: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $citizen-details-border-color;

            &-btn {
                position: relative;
                padding-left: 0;
                height: 40px;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);

                    & > .svg-icon {
                        width: 16px;
                        height: auto;
                    }
                }

                .text {
                    color: $citizen-details-accent-color;
                    padding-left: 40px;
                }
            }

            &-form {
                margin-top: 1rem;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .spinner {
            margin-top: 15%;
        }
    }

    .citizen-note-form {
        margin-top: 1rem;

        &__actions {
            text-align: right;
        }
    }
}
