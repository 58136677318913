$administration-call-list-padding: $padding;
$administration-call-list-padding-small: $padding-small;
$administration-call-list-tab-head-height: 49px;
$administration-call-list-alert-height: 70px;

$call-list-customer-number-color: $color-key-grey;
$call-list-button-font-color: $color-white;

.administration-call-list {
    padding-top: $administration-call-list-padding-small;
    height: 100vh;
    display: flex;
    flex-direction: column;

    >* {
        width: 100%;
    }

    &__list {
        @include ul-reset;
    }

    li {
        border-top: 1px solid rgba(0, 0, 0, .1);
    }

    &__interval-header {
        color: $call-list-customer-number-color;
        padding: $administration-call-list-padding $administration-call-list-padding 0;
    }

    .alert-info {
        width: auto;
        margin: $administration-call-list-padding-small;
        margin-top: 0;
    }

    &__hidden-scroll .util-hidden-scroll {
        height: calc(100vh - #{$administration-call-list-tab-head-height + $administration-call-list-padding-small}) !important;

        @include when-inside('.administration-call-list_alert') {
            height: calc(100vh - #{$administration-call-list-tab-head-height + $administration-call-list-alert-height + $administration-call-list-padding}) !important;
        }
    }

    .mat-mdc-tab-body-content {
        overflow: hidden;
    }
}

.call-list {
    &-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        padding: $administration-call-list-padding-small $administration-call-list-padding;
        transition-duration: .1s;

        &:hover {
            background-color: rgba(0, 0, 0, .03);
        }

        &__customer {
            &-number {
                font-weight: map-get($font-weights, base);
            }
            &-phone-number {
                color: $call-list-customer-number-color;
            }
        }

        &-count {
            margin-left: 4px;
        }
    }

    &-button {
        svg {
            max-width: 18px;
            min-width: 18px;
        
            path {
                fill: $call-list-button-font-color;
            }
        }
    }
}
