$util-hidden-scroll-spacing: $app-padding * 2;

.util-hidden-scroll {
    &_active {
        overflow-y: scroll;
        overflow-x: hidden;
        margin-right: -$util-hidden-scroll-spacing;
        padding-right: $util-hidden-scroll-spacing;
        transform: translateZ(0); // Bugfix for Chrome on OSX not respecting z-index on scrollbars
    }

    &__inner {
        position: relative;

        @include when-inside('.util-hidden-scroll_active') {
            height: 100%;
        }
    }
}
