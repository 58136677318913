@use "@angular/material" as mat;

.mdc-dialog {
    .mdc-dialog__actions,
    .mdc-dialog__content {
        padding: 20px 24px 20px 24px;
        overflow: hidden !important;
    }

    .mdc-dialog__actions {
        justify-content: flex-start;
        gap: 20px;
        flex-direction: row-reverse;
    }

    .mdc-dialog__content+.mdc-dialog__actions {
        padding-top: 0;
    }
}

.mdc-dialog__surface {
    overflow: initial !important;
}

.mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: $color-black;
}

// helper classes for changing density of the dialog content
.low-density-dialog-content {
    @include mat.form-field-density(-5);
    @include mat.radio-density(-5);
    @include mat.select-density(-5);
    @include mat.option-density(-5);
    @include mat.datepicker-density(-5);
    @include mat.icon-density(-5);
    @include mat.icon-button-density(-5);
  }

.mat-mdc-dialog-content {
    dd {
        color: $color-black;
    }
}
