$commerce-addtobasket-quantity-border: #4A4A4A;
$commerce-addtobasket-quantity-controls-disabled: $color-search-grey;
$commerce-addtobasket-quantity-in-basket: $color-orange;
$commerce-addtobasket-quantity-in-basket-check: $color-dark-green;
$commerce-addtobasket-quantity-container-background: $color-white;

.commerce-addtobasket {
    @include row;
    justify-content: flex-end;
    align-items: center;

    &__sold-out {
        justify-content: center;
        align-items: center;
    }

    &__quantity-container {
        display: flex;

        @include when-inside('.commerce-addtobasket_compact-mode') {
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%);
            width: 150px;
            height: 100%;
            background-color: $commerce-addtobasket-quantity-container-background;
            z-index: 2;
        }

        @include when-inside('.commerce-addtobasket_compact-mode.commerce-addtobasket_simple-mode', '.commerce-addtobasket_minibasket-mode') {
            width: 200px;
        }
    }

    &__quantity-controls {
        position: relative;
        display: flex;
        margin-right: 10px;

        @include when-inside('.commerce-addtobasket_compact-mode', '.commerce-addtobasket_simple-mode') {
            border: 1px solid $commerce-addtobasket-quantity-border;
            border-radius: 50px;
            padding: 2px;
            margin-right: 0;

            &_has-focus {
                border: 1px solid $commerce-addtobasket-quantity-in-basket;
            }

            &_disabled {
                border-color: $commerce-addtobasket-quantity-controls-disabled;
            }
        }
    }

    &__quantity {
        width: 50px;
        padding: 8px 0;
        text-align: center;
        border: 1px solid $commerce-addtobasket-quantity-border;
        border-radius: 50px;

        &:disabled {
            background-color: $color-white;
            color: $commerce-addtobasket-quantity-controls-disabled;
        }

        &::selection {
            background-color: $commerce-addtobasket-quantity-in-basket;
            color: $color-white;
        }

        @include when-inside('.commerce-addtobasket__quantity-controls_has-focus') {
            border: 1px solid $commerce-addtobasket-quantity-in-basket;
            color: $commerce-addtobasket-quantity-in-basket;
        }

        @include when-inside('.commerce-addtobasket_compact-mode', '.commerce-addtobasket_simple-mode') {
            border: none;
            border-radius: 0;
        }
    }

    &__quantity-in-basket {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: $commerce-addtobasket-quantity-in-basket;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        overflow: visible;

        .svg-icon {
            fill: $commerce-addtobasket-quantity-in-basket-check;
            width: 12px;
            height: 12px;
            margin-right: 3px;
        }
    }

    &__quantity-overlay {
        display: none;

        @include when-inside('.commerce-addtobasket_compact-mode') {
            display: block;
            position: absolute;
            opacity: 0;
            z-index: -1;
            top: 0;
            left: 0;
            background-color: rgba(black, 0.4);
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    &__increment,
    &__decrement {
        display: none !important;

        @include when-inside('.commerce-addtobasket_compact-mode', '.commerce-addtobasket_simple-mode') {
            display: block !important;
            font-size: 22px;
        }
    }

    &__button,
    &__button-quantity {
        min-width: 80px !important;
        line-height: 39px !important;

        @include when-inside('.commerce-minibasket-item') {
            min-width: 50px !important;
            padding: 0px !important;
        }

        &[disabled],
        &:disabled {
            pointer-events: none;
        }
    }

    &__button-quantity {
        border: 1px solid $commerce-addtobasket-quantity-border !important;
    }

    &__remove {
        height: 26px !important;
        width: 26px !important;
        line-height: 26px !important;
        margin-left: 10px !important;

        @include when-inside('.commerce-addtobasket_compact-mode') {
            margin-left: 0 !important;
            margin-right: 10px !important;
        }

        &__sold-out {
            @include when-inside('.commerce-addtobasket_compact-mode') {
                margin-right: 0 !important;
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
            padding: 1px 0 !important;
            min-height: 26px !important;
        }

        .svg-icon {
            width: 15px;
            height: 15px;
        }
    }
}
