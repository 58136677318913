$quicklinks-border-color: $color-border-grey;

.quicklinks {
    display: block;

    &__item {
        @include truncate-text;
        position: relative;
        display: block;
        font-size: 16px;
        padding: 1rem 1rem 1rem 0;
        border-bottom: 1px solid $quicklinks-border-color;

        &:first-child {
            border-top: 1px solid $quicklinks-border-color;
        }

        &::after {
            display: block;
            content: '>';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: .5rem;
        }
    }
}
