$page-not-found-padding: $padding-small;

.page-not-found {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    &__title {
        font-size: 60px;
        line-height: 60px;
        margin-bottom: $page-not-found-padding;
    }

    &__text {
        margin-bottom: $page-not-found-padding;
    }
}
