$category-menu-border: $color-border-grey;
$category-menu-active: $color-orange;
$category-menu-spacing: $padding;
$category-menu-aside-background: $color-light-grey;
$category-menu-aside-z-index: map-get($z-layers, top);

.category-menu {
    padding-top: 1px;

    @include when-inside(".app_customer") {
        margin-bottom: $category-menu-spacing;
    }

    @include when-inside(".app_customer-center") {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $category-menu-aside-z-index;
        width: 100%;
        padding: px-to-rem($category-menu-spacing);
        background-color: $category-menu-aside-background;
        min-height: 100%;
    }

    &__level {
        @include when-inside(".category-menu__level") {
            padding-left: 15px;
        }
    }

    &__header {
        @include truncate-text;
        font-size: 14px;
        position: relative;
        margin-top: -1px;
        display: block;
        border-top: 1px solid $category-menu-border;
        line-height: 38px;
        padding-left: 10px;
        margin-bottom: 0;
    }

    &__link {
        @include truncate-text;
        position: relative;
        margin-top: -1px;
        display: block;
        border-top: 1px solid $category-menu-border;
        border-bottom: 1px solid $category-menu-border;
        line-height: 38px;
        padding-left: 10px;
        cursor: pointer;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
        }

        &:hover::before {
            background-color: $color-border-grey;
        }

        &_active {
            color: $color-orange !important;

            &::before {
                background-color: $color-orange !important;
            }
        }

        &_disable {
            pointer-events: none;
            color: rgb(0 0 0 / 20%);
        }

        &_has-children {
            padding-right: 20px;
            .category-menu__link-icon.forward {
                display: block;
            }

            &.category-menu__link_active {
                & + .category-menu__children {
                    display: block;
                }
                .category-menu__link-icon {
                    &.forward {
                        display: none;
                    }
                    &.down {
                        display: block;
                    }
                }

                &.category-menu__link_close {
                    & + .category-menu__children {
                        display: none;
                    }
                    .category-menu__link-icon {
                        &.forward {
                            display: block;
                        }
                        &.down {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &__link-icon {
        display: none;
        .svg-icon {
            height: 10px;
            width: 10px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__children {
        display: none;
    }
}
