.administration-user-history {

    &__form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        width: 100%;
    }

    .form-builder-element__currentUsername {
        flex: 1 1 60%;
        max-width: 60%;
    }

    .mat-expansion-panel-header.mat-expanded {
        height: auto !important;
    }

    .mat-expansion-panel.mat-expanded {
        padding-bottom: 30px;
    }

    .mat-expansion-panel .mat-content {
        flex-direction: row !important;
    }

    .mat-expansion-panel-header-title {
        flex-direction: column;
        align-items: flex-start;
    }

    .mat-expansion-panel-header-description {
        justify-content: space-between;
        align-items: center;
    }

    &__icon {
        width: 25px;
        height: 17px;
    }

    &__user {
        display: flex;
        flex-direction: column;
    }

    .cdk-row:nth-child(even) {
        background-color: $color-light-grey;
    }

    .cdk-column-index {
        width: 60px;
        flex: 0 1 auto;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
    }

    &__fetching {
        flex: 0 1 auto;
    }
}
