$administration-transfer-reasons-padding: $padding-small;

.transfer-reasons {

    &-list {
        display: inline-flex;
        flex-direction: column;
        padding-bottom: $administration-transfer-reasons-padding;
    }

    &-buttons {
        display: flex;
        justify-content: flex-end;

        & &__cancel {
            margin-right: 5px;
        }
    }
}
