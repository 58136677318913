$layout-navigation-hover: $color-orange;

$layout-navigation-border: $color-divider;
$layout-navigation-border-height: 1px;

$layout-navigation-right-text: $text-half;
$layout-navigation-item-height: $header-top-height;
$layout-navigation-item-height-device: $header-top-height-device;
$layout-navigation-background: $color-white;
$layout-navigation-impersonated-background: $color-grey;
$layout-navigation-impersonated-text: $color-white;

$layout-navigation-trigger-background: $color-search-grey;
$layout-navigation-trigger-margin: $padding-small;
$layout-navigation-trigger-margin-device: 5px;

$layout-navigation-basket-quantity-background: $color-error;
$layout-navigation-basket-quantity-text: $text-contrast;
$layout-navigation-basket-quantity-size: 23px;

.layout-navigation {
    position: relative;
    z-index: z('top');

    &__row {
        @include row;

        @include when-inside(".layout-navigation_logged-in") {
            border-bottom: $layout-navigation-border-height solid $layout-navigation-border;
        }

        &--dev-mode {
            background-color: $color-alert-info;
        }
    }

    @include when-inside(".app_customer") {
        @include container;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $layout-navigation-background;

        @include respond-to(tablet) {
            padding-right: 0 !important;
            padding-left: 0 !important;

            &__row:last-child {
                padding-left: $layout-navigation-trigger-margin;
                padding-right: $layout-navigation-trigger-margin;
            }
        }

        @include respond-to(mobile) {
            padding-right: 0 !important;
            padding-left: 0 !important;

            &__row {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    &__left {
        @include col;
        @include row;
    }

    &__logo {
        display: flex;
        align-items: center;
        height: $layout-navigation-item-height - $layout-navigation-border-height;

        @include respond-to(mobile, tablet) {
            height: $layout-navigation-item-height-device - $layout-navigation-border-height;
        }

        .svg-icon {
            width: 138px;
            height: 44px;

            @include respond-to(mobile, tablet) {
                width: 94px;
                height: 30px;
            }
        }

        @include when-inside('.layout-navigation_admin') {
            height: 50px;
            align-self: center;
            flex: 1;

            .svg-icon {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__right {
        @include col;
        @include row;
        justify-content: flex-end;
    }

    &__item {
        position: relative;
        height: $layout-navigation-item-height - $layout-navigation-border-height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 7px;

        @include respond-to(tablet) {
            height: $layout-navigation-item-height-device - $layout-navigation-border-height;
            padding: 0 4px;
        }

        @include when-inside(".layout-navigation__left") {
            font-size: 18px;

            @include respond-to(tablet) {
                font-size: 14px;
            }
        }

        @include when-inside(".layout-navigation__right") {
            color: $layout-navigation-right-text;
            font-size: 12px;

            @include respond-to(mobile, tablet) {
                font-size: 10px;
            }
        }

        @include respond-to(mobile) {
            display: none;

            @include when-inside(".app_customer.app_homecarer-mode") {
                display: flex;
                height: auto;
            }
        }

        &:hover,
        &_active {
            color: $layout-navigation-hover;

            &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                display: block;
                width: 100%;
                height: 4px;
                background-color: $layout-navigation-hover;
            }

            .layout-navigation__icon {
                color: $layout-navigation-hover;
            }

            @include when-inside(".layout-navigation__right") {
                color: $layout-navigation-hover;
            }
        }
    }

    &__basket {
        padding-right: 10px;

        @include respond-to(mobile) {
            display: inherit;
            height: $layout-navigation-item-height-device - $layout-navigation-border-height;
            padding: 0 4px;
        }
    }

    &__basket-container {
        position: relative;
    }

    &__basket-quantity {
        position: absolute;
        font-size: 10px;
        top: -8px;
        right: -8px;
        padding: 0 5px;
        height: $layout-navigation-basket-quantity-size;
        min-width: $layout-navigation-basket-quantity-size;
        text-align: center;
        line-height: $layout-navigation-basket-quantity-size;
        background-color: $layout-navigation-basket-quantity-background;
        color: $layout-navigation-basket-quantity-text;
        border-radius: 100%;
    }

    &__menu-trigger {
        display: none !important;

        @include respond-to(mobile) {
            &_mobile {
                margin: $layout-navigation-trigger-margin-device 0 !important;
                display: inline-block !important;
            }
        }

        @include respond-to(tablet) {
            &_tablet {
                margin: $layout-navigation-trigger-margin-device $layout-navigation-trigger-margin-device $layout-navigation-trigger-margin-device 0 !important;
                display: inline-block !important;
            }
        }

        @include respond-to(desktop) {
            &_desktop {
                margin: $layout-navigation-trigger-margin $layout-navigation-trigger-margin $layout-navigation-trigger-margin 0 !important;
                display: inline-block !important;
            }
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__search {
        @include col;
    }

    &__icon {
        margin: 0 auto 5px;
        text-align: center;
    }

    &__text {
        @include respond-to(mobile, tablet) {
            line-height: 5px;
        }
    }

    &__impersonated {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        background-color: $layout-navigation-impersonated-background;
        color: $layout-navigation-impersonated-text;
        line-height: 20px;

        &-citizen {
            padding: 0 3px;
        }

        &-delivery {
            padding: 0 3px;
        }
    }
}
