$commerce-orders-border-color: $color-divider;
$commerce-orders-grey: $color-key-grey;
$commerce-orders-spacing: $padding-small;
$commerce-orders-highlight: $color-orange;
$commerce-orders-check: $color-dark-green;
$commerce-orders-cross: $color-error;

.commerce-orders {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__order {
        &-header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: baseline;

            @include respond-to(mobile) {
                margin-bottom: 1rem;
            }
        }

        &-content {
            padding: $commerce-orders-spacing 0;
        }

        &-head {
            margin: 0;
        }

        &-delivery-day {
            flex-direction: column;
            color: $commerce-orders-grey;
            align-items: flex-start !important;
        }

        &-status {

            @include respond-to(desktop, tablet) {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
            }

            .svg-check {
                fill: $commerce-orders-check;
                height: 15px;
                width: 15px;
                margin-right: 5px;
            }

            .svg-cross,
            .svg-magnifying-glass {
                fill: $commerce-orders-cross;
                height: 13px;
                width: 13px;
                margin-right: 5px;
            }
        }
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        margin-top: $commerce-orders-spacing;
        margin-bottom: $commerce-orders-spacing;

        @include respond-to(mobile) {
            flex-direction: column;
        }
    }

    &__controls-container {
        display: flex;

        @include respond-to(desktop) {
            justify-content: flex-end;
            align-items: center;
        }

        @include respond-to(mobile, tablet) {
            flex-direction: column;
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__cancel-and-copy,
    &__cancel,
    &__btn,
    &__form {
        @include respond-to(desktop) {
            margin-left: 10px !important;
        }

        @include respond-to(mobile, tablet) {
            margin-top: 10px !important;
        }
    }

    &__pdf {
        display: flex;
        align-items: center;

        >.svg-icon {
            width: 30px;
            height: 30px;
        }
    }

    &__summary {
        display: flex;
        justify-content: flex-end;
    }

    @include when-inside('.citizen-details') {
        &__order {
            .mat-expansion-panel-header {
                padding: 10px 0 10px 40px !important;
            }
        }
    }
}
