$commerce-receipt-background: $color-light-grey;
$commerce-receipt-check: $color-dark-green;
$commerce-receipt-border-color: $color-divider;
$commerce-receipt-head-text: $color-key-grey;
$commerce-receipt-head-padding: 10px;
$commerce-receipt-spacing: $padding;
$commerce-receipt-spacing-small: $padding-small;

.commerce-receipt {
    height: 100%;
    margin-top: $commerce-receipt-spacing-small;

    &__container {
        @include respond-to(tablet, desktop) {
            background-color: $commerce-receipt-background;
            padding: $commerce-receipt-spacing;
            margin: 0 auto;
            max-width: 800px;
        }
    }

    &__status {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__row {
        @include row;
    }

    &__check {
        .svg-icon {
            display: block;
            fill: $commerce-receipt-check;
            height: 75px;
            width: 75px;
            border: 5px solid $commerce-receipt-check;
            border-radius: 100%;
            padding: 10px;
            margin: 0 auto;
        }
    }

    &__head {
        text-align: center;
    }

    &__sub-head {
        margin-bottom: 0;
    }

    &__delivery {
        text-align: center;
        margin-bottom: $commerce-receipt-spacing;
    }

    &__info {
        margin-bottom: $commerce-receipt-spacing;

        &-key {
            font-weight: 500;
        }
    }

    &__order {
        margin-bottom: $commerce-receipt-spacing;

        &-key {
            @include col;
            font-weight: 500;
        }

        &-value {
            @include col;
            text-align: right;
        }
    }

    &__go-to-frontpage,
    &__continue-shopping {
        width: 75%;
        display: block !important;
        margin: 0 auto !important;
    }

    &__continue-shopping {
        margin-bottom: $commerce-receipt-spacing !important;
    }
}
