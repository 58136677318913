iv-progress-button {
    display: inline-block;

    &.btn-with-icon {

        .svg-icon {
            @extend %svg-icon;
            width: 20px;
            height: 20px;
        }

        .mdc-button__label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }
}

.progress-button {
    position: relative;
    width: auto;

    &_full-width {
        width: 100%;
    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        transition-duration: .2s;

        .loading & {
            opacity: 0;
        }
    }

    &__spinner-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //TODO: INT-2007: add custom spinner until cpu burn is fixed
        &__spinner {
            &.primary{
                border: 3px solid $color-dark-green;
                border-bottom-color: transparent;
            }
            &.accent{
                border: 3px solid $color-orange;
                border-bottom-color: transparent;
            }
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            } 
        }
    }
}
