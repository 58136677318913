$administration-messages-border-color: $color-border-grey;

.administration-messages {
    @include row;

    &__item {
        @include col(16, 16, 4);
        @include row;

        margin: 0 0 2rem;
        padding: 1rem 0 0;
        border-top: 1px solid $administration-messages-border-color;
    }

    &__text {
        @include col(13, 13, 4);
        padding: 0 1rem 0 0;
        border-right: 1px solid $administration-messages-border-color;
    }

    &__info {
        @include col(3, 3, 4);
        padding: 0 0 0 1rem;
    }

    & &__btn {
        width: 100%;
        margin: .5rem 0 0;

        &:first-child {
            margin: 0;
        }
    }
}

.messages-form {
    &__actions {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
    }
}
