.dialog-form {
    min-width: 320px;

    .form {
        width: 100%;
    }

    &__content {
        overflow: hidden;
    }
}
