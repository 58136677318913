$administration-citizen-search-padding: $app-padding;
$administration-citizen-search-spacing: $app-padding-small;
$administration-citizen-search-spacing-small: 10px;
$administration-citizen-search-border: $color-divider;
$administration-citizen-search-grey-text: $color-key-grey;

.administration-citizen-search {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__top {
        padding: px-to-rem($administration-citizen-search-padding) px-to-rem($administration-citizen-search-padding) 0;
        border-bottom: 1px solid $administration-citizen-search-border;
    }

    &__middle {
        flex: 1;
        overflow: hidden;
    }

    &__bottom {
        border-top: 1px solid $administration-citizen-search-border;
        background-color: transparent;
    }

    &__search-head {
        margin-bottom: $administration-citizen-search-spacing;
    }

    &__filter {
        margin-top: 1rem;
        margin-left: calc(((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 2) * -1);
    }

    &__num-found {
        font-weight: 500;
        margin-top: $administration-citizen-search-spacing;
        padding-bottom: 3px;
    }

    &__citizens {
        width: 100%;
    }

    &__citizen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100px;
        border-bottom: 1px solid $administration-citizen-search-border;
        padding: $administration-citizen-search-spacing-small px-to-rem($administration-citizen-search-padding);

        em {
            color: $color-error;
            font-style: normal;
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .03);
        }

        &-name {
            margin-bottom: 0;
        }

        &-row {
            color: $administration-citizen-search-grey-text;
            font-size: 12px;

            strong {
                font-size: 12px;
            }
        }
    }

    .util-hidden-scroll__inner {
        display: flex;
    }
}
