.commerce-delivery-date {

    &__date-picker {
        padding: $commerce-basket-spacing-small * 2 $commerce-basket-spacing-small $commerce-basket-spacing-small;
        display: flex;
        align-items: flex-start;

        &--compact {
            padding-top: $commerce-basket-spacing-small;
            border-top: 1px solid $color-divider;
        }

        &--fetching {
            justify-content: center;
        }
    }

    &__picker {
        width: auto;

        &--full-width {
            width: 100%;
        }

    }

    &__preferred-delivery {
        display: flex;
        flex-wrap: wrap;

        gap: 5px;
        margin-left: $commerce-basket-spacing-small;
        margin-top: -10px;

        &-text {
            color: rgba(0, 0, 0, 0.54);
            font-size: 10px;
            font-weight: 500;
            width: 100%;
        }

        /*&-btn {
            margin-right: 5px !important;
            margin-top: 4px !important;
            line-height: 25px !important;

            &:last-child {
                margin-right: 0 !important;
            }
        }*/
    }
}
