@use "sass:math";

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, $number * 0 + 1)
    }

    @return $number;
}

@function px-to-em ( $pixels,
$base: map-get($font-sizes, base)) {
    // $base could be in em or px (no unit = px).
    // Adjust accordingly to create a $divisor that
    // serves as context for $pixels.
    $multiplier: if(unit($base)==em, map-get($font-sizes, base), 1);
    $divisor: strip-unit($base) * $multiplier;

    $em-vals: ();
    @each $val in $pixels {
        $val-in-ems: math.div(strip-unit($val), $divisor) * 1em;
        $em-vals: append($em-vals, $val-in-ems);
    }

    @if length($em-vals)==1 {
        // return a single value instead of a list,
        // so it can be used in calculations
        @return nth($em-vals, 1);
    }
    @else {
        @return $em-vals;
    }
}

@function px-to-rem ( $pixels,
$base: map-get($font-sizes, base)) {
    $rem-vals: ();
    @each $val in $pixels {
        $val-in-rems: calc(strip-unit($val) / strip-unit($base) * 1rem);
        $rem-vals: append($rem-vals, $val-in-rems);
    }

    @if length($rem-vals)==1 {
        // return a single value instead of a list,
        // so it can be used in calculations
        @return nth($rem-vals, 1);
    }
    @else {
        @return $rem-vals;
    }
}

.text-center {
    text-align: center;

    &_mobile {
        @include respond-to(mobile) {
            text-align: center;
        }
    }
}

.text-right {
    text-align: right;

    &_mobile {
        @include respond-to(mobile) {
            text-align: right;
        }
    }
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.magic-width {
    width: 80%;
    margin: 0 auto;

    @include respond-to(desktop) {
        width: 40%;
    }
}

.center-content {
    @include center-content;
    flex: 1;
}

.scrollbox {
    overflow: auto;
    background:
        /* Shadow covers */
        linear-gradient(white 30%, rgba(255,255,255,0)) 100% 0,
        linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

        /* Shadows */
        radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)) 100% 0,
        radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

@keyframes spin {
    0%  { transform: rotate(0deg); }
	100%{ transform: rotate(360deg); }
}


.clear-input-arrow {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}